import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Header } from 'components';
import { Button, Icon } from 'components/shared';
import { Footer } from 'layouts/Main/Footer';

import hero from 'images/hero.png';
import feature1 from 'images/feature1.svg';
import feature2 from 'images/feature2.svg';
import feature3 from 'images/feature3.svg';
import feature4 from 'images/feature4.svg';
import car from 'images/car.png';
import app from 'images/app.png';

import ym from 'react-yandex-metrika';
import { FindBus } from './FindBus';

import {
  BottomContainer, Container, Features, Hero, Info, MobileApp,
} from './styles';

const Main = () => {
  const [imageOffset, setImageOffset] = useState(0);
  const imageRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    const onResize = () => {
      setImageOffset(formRef.current.clientHeight - (imageRef.current.clientHeight * 0.33));
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Online Bus Station</title>
      </Helmet>
      <Hero>
        <Header />
        <div>
          <img src={hero} alt="hero" />
          <Info>
            <h2>
              Online Bus Station - онлайн бронирование мест в автобусах
            </h2>
            <p>
              Выбирай самое подходящее время и место отправления и будь всегда и везде вовремя!
            </p>
            <Button
              primary
              onClick={() => {
                ym('reachGoal', 'gotobooking');
                document.getElementById('findBusForm')
                  .scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                  });
              }}
            >
              Забронировать
            </Button>
          </Info>
        </div>
      </Hero>
      <Features>
        <h2>Преимущества</h2>
        <div>
          <div>
            <img src={feature1} alt="feature1" />
            Только проверенные перевозчики
          </div>
          <div>
            <img src={feature2} alt="feature2" />
            Никаких дополнительных расходов
          </div>
          <div>
            <img src={feature3} alt="feature3" />
            Объединение интересов перевозчиков и пассажиров
          </div>
          <div>
            <img src={feature4} alt="feature4" />
            Различные акции и скидки
          </div>
        </div>
      </Features>
      <BottomContainer imageOffset={imageOffset}>
        <img ref={imageRef} src={car} alt="car" />
        <FindBus innerRef={formRef} />
        <MobileApp>
          <div>
            <p>
              Online Bus Station - это новый бесплатный способ быстро и
              легко спланировать свою поездку на автобусах пригородного и междугороднего
              <b> регулярного </b>
              сообщения.
            </p>
            <p>
              Скачай официальное приложение &quot;OBS.BY&quot; в App Store или Google Play
              и твой &quot;мобильный диспетчер&quot; всегда будет с тобой.
            </p>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.obs.client"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="googlePlay" size={48} color="#000000" />
              </a>
              <a
                href="https://itunes.apple.com/ru/app/obs-by/id1275482887?l=ru&ls=1&mt=8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="apple" size={48} color="#000000" />
              </a>
            </div>
          </div>
          <img src={app} alt="app" />
        </MobileApp>
      </BottomContainer>
      <Footer />
    </Container>
  );
};

export default Main;
