const getColor = (color, type) => {
  let obj = {};
  switch (color) {
    case 'orange':
      obj = {
        default: '#fb6d2e',
        hover: '#fb7b42',
        active: '#e16229',
      };
      break;
    case 'red':
      obj = {
        default: '#ff4040',
        hover: '#ff0505',
        active: '#f10000',
      };
      break;
    default:
      break;
  }
  return obj[type];
};

export { getColor };
