import styled from 'styled-components';

const IconSvg = styled.svg.attrs((props) => ({
  viewBox: '0 0 1024 1024',
  width: `${props.size}px`,
  height: `${props.size}px`,
}))`
  cursor: ${(props) => (props.hoverColor ? 'pointer' : 'inherit')};
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: calc(50% - ${(props) => props.size / 2}px);
  top: calc(50% - ${(props) => props.size / 2}px);
  path {
    fill: ${(props) => props.color};
    transition: all 0.3s;
  }
  :hover {
    path {
      fill: ${(props) => (props.hoverColor ? props.hoverColor : props.color)};
    }
  }
`;

const IconWrapper = styled.div`
  position: relative;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  min-width: ${(props) => `${props.size}px`};
  min-height: ${(props) => `${props.size}px`};
  margin: 0;
`;

export { IconSvg, IconWrapper };
