import styled from 'styled-components';

const FormContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 35px;
  max-width: 490px;
  margin: 0 auto;
  form { 
    & > div {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &:last-child {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        > button {
          margin: 5px;
        }
      }
    }
  }

  @media (max-width: 575px) {
    padding: 15px;
    font-size: 16px;
  }
`;

const FormHeader = styled.div`
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 45px;

  @media (max-width: 575px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

export {
  FormContainer,
  FormHeader,
};
