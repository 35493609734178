import { DateUtils } from 'react-day-picker';
import ru from 'date-fns/locale/ru';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

const parseDate = (str, format) => {
  const parsed = dateFnsParse(str, format, new Date(), { locale: ru });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
};
const formatDate = (date, format) => dateFnsFormat(date, format, { locale: ru });

export { parseDate, formatDate };
