import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Container } from './styles';

/* eslint-disable */
const Legal = ({ location: { pathname } }) => {
  return (
    <Container>
      {pathname === '/legal/terms' && (
        <>
          <h1>
            Пользовательское соглашение на доступ к приложению «OBS» пассажирам
          </h1>
          <h2>1. Договорные отношения</h2>
          <p>
            Настоящие Условия использования (
            <em>далее </em>
«
            <em>Условия</em>
            ») определяют использование вами (
            <em>далее</em>
«
            <em>Пользователь</em>
            »), физическим лицом, приложений, веб-сайтов, содержания, продуктов
            и услуг (
            {' '}
            <em>далее «Услуги</em>
            »), или доступ к ним, предоставляемых Обществом с ограниченной
            ответственностью «Центр цифровых транспортных технологий»,
            зарегистрированным по адресу: Минская область, Минский район,
            Боровлянский сельсовет, д. Боровая, тер. ТВЦ «Аквабел», д. 1, оф.
            419, действующим на основании Свидетельства о государственной
            регистрации №692178478 выданного Управлением регистрации и
            лицензирования главного управления юстиции Минского городского
            исполнительного комитета (
            <em>далее «Исполнитель») </em>
          </p>
          <p>
            ПОЖАЛУЙСТА, ВНИМАТЕЛЬНО ПРОЧИТАЙТЕ ЭТИ ПРАВИЛА ДО ПОЛУЧЕНИЯ ДОСТУПА
            К УСЛУГАМ ИЛИ ИХ ИСПОЛЬЗОВАНИЯ.
          </p>
          <p>
            Ваш доступ к Услугам и их использование означают ваше согласие с
            настоящими Условиями, которые устанавливают договорные отношения
            между Пользователем и Исполнителем. Если вы не соглашаетесь с
            настоящими Условиями, вы не можете получить доступ к Услугам или
            использовать их.
          </p>
          <p>
            Настоящие Условия в явной форме заменяют предшествующие соглашения
            или договоренности с вами. Что касается вас, Исполнитель может
            немедленно отменить настоящие Условия или любые Услуги, прекратить
            предлагать Услуги в целом либо лишить доступа к Услугам или к любой
            их части, в любое время и по любой причине, которая является с вашей
            стороны намеренным умыслом совершить действия/бездействия в
            отношении Исполнителя или привлекаемых, независимых между собой,
            подрядчиков, способный причинить физический, преступный или
            материальный вред.
          </p>
          <p>
            К определенным Услугам могут применяться дополнительные условия,
            такие как: политика в отношении особого случая, деятельности или
            мероприятий рекламного характера; при этом такие дополнительные
            правила будут раскрыты вам в связи с соответствующими Услугами.
            Дополнительные условия являются дополнением к Условиям по
            предоставлению соответствующих Услуг и считаются неотъемлемой частью
            настоящих Условий. Дополнительные условия имеют преимущественную
            силу над настоящими Условиями в случае, если они вступают в
            противоречие с ними в отношении соответствующих Услуг.
          </p>
          <p>
            Исполнитель может время от времени изменять Условия, связанные с
            предоставляемыми Услугами. Поправки вступают в силу после публикации
            в данном месте Исполнителем обновленных Условий или политики с
            внесенными поправками, либо дополнительных условий по предоставлению
            соответствующих Услуг. Ваш постоянный доступ к Услугам или
            использование вами Услуг после такой публикации представляют собой
            ваше согласие с измененными Услугами.
          </p>
          <p>
            Сбор и использование нами личной информации в связи с оказываемыми
            Услугами происходит в соответствии с Политикой конфиденциальности,
            которая размещена по адресу:
            <Link to="/legal/privacy">https://obs.by/legal/privacy</Link>
.
            Исполнитель может предоставить обработчику претензий или страховщику
            любую необходимую информацию (включая вашу контактную информацию),
            при наличии жалобы, спора или конфликта, которые могут включать
            несчастный случай с вашим участием и с участием третьего лица
            (включая водителя транспортной компании), и если такая информация
            или данные необходимы, чтобы разрешить жалобу, спор или конфликт.
          </p>
          <h2>
            2. Условия использования приложения пользователем (пассажиром).
          </h2>
          <h3>2.1. Вид предоставляемых услуг.</h3>
          <p>
            Услуги представляют собой технологическую платформу, которая
            позволяет пользователям мобильных приложений «OBS» или пользователям
            веб-сайта obs.by, предоставляемых в рамках оказываемых Услуг (каждое
            из них именуемое «
            <em>Приложение</em>
            »), организовать и спланировать перевозку и/или предоставление
            логистических услуг с участием независимых сторонних поставщиков
            таких услуг, включая независимые сторонние транспортные компании и
            независимых поставщиков логистических услуг в соответствии с
            соглашением с Исполнителем или с аффилированными лицами Исполнителя
            («
            {' '}
            <em>Независимые поставщики</em>
            »). Если Исполнитель не заключил с вами отдельного письменного
            соглашения, Услуги предоставляются вам исключительно для вашего
            личного, некоммерческого использования.
          </p>
          <p>
            Вы признает, что компания Исполнитель не обеспечивает осуществление
            перевозок и не функционирует как транспортная компания, и что такие
            перевозки предоставляются независимыми сторонними подрядчиками,
            которые не являются сотрудниками компании Исполнителя или ее
            аффилированных лиц.
          </p>
          <p>
            Вы признаете, что Услуги могут быть доступны под брендами отдельных
            дочерних компаний или аффилированных лиц компании Исполнителя, или
            независимыми сторонними поставщиками, включая водителей транспортных
            компаний, держателями разрешений на чартер или держателями подобных
            разрешений на транспортировку, держателями санкций или лицензий.
          </p>
          <p>
            Услуги и все права, изложенные в настоящем документе, являются и
            остаются собственностью компании Исполнителя или ее лицензиаров. Ни
            настоящие Условия, ни использование вами Услуг не передают и не
            предоставляют вам прав на:
          </p>
          <ul>
            <li>
              Услуги или связанные с ними права, за исключением ограниченной
              лицензии, предоставленной ниже;
            </li>
            <li>
              использование фирменного наименования, логотипов, наименований
              продуктов или услуг, торговых марок или знаков обслуживания
              Исполнителя или лицензиаров Исполнителя, или на ссылку на них.
            </li>
          </ul>
          <h3>2.2. Лицензия.</h3>
          <p>
            При соблюдении вами настоящих Условий Исполнитель предоставляет вам
            ограниченную, неисключительную, не подлежащую сублицензированию,
            отзывную, непередаваемую лицензию на:
          </p>
          <ul>
            <li>
              доступ к Приложениям и использование Приложений на вашем личном
              устройстве исключительно в связи с использованием вами Услуг;
            </li>
            <li>
              использование любого содержания, информации и связанных материалов
              и доступ к ним, которые могут предоставляться посредством оказания
              Услуг, в каждом случае исключительно в ваших личных,
              некоммерческих целях.
            </li>
          </ul>
          <p>
            Любые права, явно не предоставленные в этом документе, остаются за
            компанией Исполнителя и ее лицензиарами.
          </p>
          <h3>2.3. Ограничения при использовании приложения.</h3>
          <p>Вы не вправе:</p>
          <ul>
            <li>
              удалять любые упоминания об авторском праве, торговой марке или
              другие указания правообладателя из любой части Услуг;
            </li>
            <li>
              воспроизводить, изменять, подготавливать производные работы,
              основанные на Услугах, распределять, передавать права на
              использование Услуг, сдавать в аренду, продавать, перепродавать,
              передавать, публично демонстрировать, публично выполнять,
              передавать, передавать в эфир или иначе использовать Услуги, за
              исключением случаев, прямо разрешенных компанией Исполнителя;
            </li>
            <li>
              декомпилировать, перепроектировать или демонтировать Услуги за
              исключением случаев, разрешенных действующим законодательством;
            </li>
            <li>
              привязываться к любой части Услуг, зеркально отображать любую
              часть Услуг, или создавать фрейм любой части Услуг;
            </li>
            <li>
              вызывать или запускать любые программы или скрипты с целью
              очистки, индексации, рассмотрения или применения другого способа
              сбора данных любой части Услуг, или незаконного затруднения или
              препятствования выполнению и/или функциональности любого аспекта
              Услуг, или пытаться получить несанкционированный доступ к Услугам
              или нарушить какой-либо аспект Услуг или связанных с ними систем
              или сетей.
            </li>
          </ul>
          <h3>2.4.Услуги и контент сторонних поставщиков.</h3>
          <p>
            Услуги могут быть доступны или получены в связи с услугами и
            контентом сторонних поставщиков (включая рекламу), которые не
            контролируются компанией Исполнителя. Вы признаете, что различные
            условия использования услуг и политика конфиденциальности могут
            применяться к использованию вами таких услуг и контента сторонних
            поставщиков.
          </p>
          <p>
            Компания Исполнитель не поддерживает такие услуги и контент
            сторонних поставщиков и ни при каких обстоятельствах не будет нести
            ответственности за какие-либо продукты или услуги таких сторонних
            поставщиков. Кроме того, Apple Inc., Google, Inc., Microsoft
            Corporation или BlackBerry Limited и/или их соответствующие
            международные дочерние компании и аффилированные лица будут являться
            сторонними бенефициарами по отношению к этому договору, если вы
            получаете доступ к Услугам с помощью Приложений, разработанных для
            Apple iOS, Android, Microsoft Windows, или для мобильных устройств
            под управлением Blackberry, соответственно. Эти сторонние
            бенефициары не являются сторонами по настоящему договору и никоим
            образом не отвечают за предоставление или поддержку Услуг. Ваш
            доступ к Услугам с использованием этих устройств должен
            осуществляться в соответствии с условиями, сформулированными в
            соответствующих правилах использования услуг сторонних бенефициаров.
          </p>
          <h2>3. Использование вами Услуг</h2>
          <h3>3.1. Учетные записи пользователей.</h3>
          <p>
            Для того, чтобы пользоваться большинством аспектов Услуг, вы должны
            зарегистрироваться и иметь активную личную пользовательскую
            Сервисную Учетную запись (
            <em>«Учетная запись»</em>
            ). Вам должно, быть как минимум 18 лет или вы должны достигнуть
            совершеннолетия в вашей юрисдикции (если это не 18 лет), чтобы
            зарегистрировать Учетную запись. В случае, если вам менее 18 лет,
            компания может отказать в создании Сервисной Учетной записи, но вам
            не будет ограничено право пользования услугами сторонних поставщиков
            через Сервисную учетную запить родителя или законного опекуна,
            назначенного судом. При регистрации Учетной записи необходимо
            предоставить компании Исполнителю определенную личную информацию,
            такую как ваше имя, адрес, номер мобильного телефона. Вы
            соглашаетесь обеспечивать точность, полноту и актуальность
            информации вашей Учетной записи. Если вы не в состоянии обеспечивать
            точность, полноту и актуальность информации в вашей Учетной записи,
            это может привести к тому, что вы не сможете получать доступ к
            Услугам и использовать Услуги, либо к расторжению компанией
            Исполнителя настоящего Соглашения с вами.
          </p>
          <p>
            Вы несете ответственность за всю деятельность, которая происходит в
            вашей Учетной записи, и соглашаетесь ни при каких обстоятельствах не
            передавать права на использование учетной записи иным третьим лицам.
            Если иное не разрешено компанией Исполнителя в письменной форме, вы
            можете владеть только одной Учетной записью на один действующих
            личный номер телефона. Учетных записей может быть несколько в
            зависимости от количества номеров телефона, которые вы используете,
            однако заказа будет принят диспетчером или оформлен только с того
            устройства и с того номера телефона с которого будет выполнен вход в
            программу.
          </p>
          <p>
            В случае, если Исполнителем будет обнаружено, что вы используете
            номер телефона не принадлежащий вам, то независимо от причин его
            использования, такая учетная запись будет заблокирована до
            дальнейшего принятия решения.
          </p>
          <p>
            Пользуясь Услугами, вы соглашаетесь соблюдать действующее
            законодательство, при этом вы можете использовать Услуги только в
            законных целях, (например, вам не разрешается осуществлять перевозку
            запрещенных или опасных материалов, находится в состоянии
            алкогольного или наркотического опьянения и иное, что запрещено
            Правилами перевозок пассажиров). При использовании Услуг вы
            обязуетесь не причинять неудобств, беспокойства, затруднений или
            материального ущерба ни Стороннему поставщику (перевозчику), ни
            какой-либо другой стороне (иным пассажирам). В определенных случаях
            вас могут попросить предоставить удостоверение личности в случае
            вызова для решения спорной ситуации сотрудников органов
            правопорядка, при этом вы соглашаетесь, что вы можете быть лишены
            доступа к Услугам или не сможете пользоваться Услугами, если вы
            откажетесь предоставить удостоверение личности.
          </p>
          <h3>3.2. Текстовые сообщения.</h3>
          <p>
            Создавая Учетную запись, вы соглашаетесь с получением текстовых
            сообщений (СМС), которые являются частью обычной деловой
            деятельности при использовании вами Услуг. Вы в любое время можете
            отказаться от получения СМС от компании Исполнитель, отправив
            электронное письмо по адресу
            {' '}
            <a href="mailto:info.obs.by@gmail.com">info.obs.by@gmail.com</a>
,
            указав, что вы больше не хотите получить такие сообщения, а также
            номер телефона мобильного устройства, на который приходят сообщения.
            Вы признаете, что отказ от получения СМС может отразиться на
            использовании вами Услуг.
          </p>
          <h3>3.3. Скидки.</h3>
          <p>
            Компания Исполнитель на собственное усмотрение может предоставлять
            скидки, которые могут быть использованы с занесением на баланс
            Учетной записи, обеспечивать другие функции или предоставлять
            какие-либо выгоды, связанные с Услугами и/или услугами Сторонних
            поставщиков согласно любым дополнительным условиям, которые компания
            Исполнитель устанавливает в отношении предоставленных скидок («
            <em>Скидки</em>
            »). Вы соглашаетесь, что Скидки:
          </p>
          <ul>
            <li>
              должны использоваться для целевой аудитории и для подобающих
              целей, законным способом;
            </li>
            <li>
              не могут дублироваться, продаваться или передаваться каким-либо
              образом, или не могут быть доступными для широкой публики (будь то
              размещение их на общественной платформе или как-то иначе), если
              это явно не разрешено компанией Исполнителя;
            </li>
            <li>
              могут быть признаны недействительными компанией Исполнитель в
              любое время по любой причине без возникновения обязательств у
              компании Исполнителя;
            </li>
            <li>
              могут использоваться только в соответствии с определенными
              условиями, которые компания Исполнитель устанавливает для такого
              вида Скидки;
            </li>
            <li>не могут обмениваться на наличные деньги;</li>
            <li>срок их действия может истечь до момента их использования.</li>
          </ul>
          <p>
            Компания Исполнитель оставляет за собой право отозвать привелегия,
            полученные с помощью Скидок вами или любым другим пользователем,
            если компания Исполнитель определяет или полагает, что использование
            Скидок произошли по ошибке, были нечестными, незаконными или
            произошли в нарушение условий использования Скидок или настоящих
            Условий.
          </p>
          <h3>3.4. Контент, предоставляемый пользователем.</h3>
          <p>
            Компания Исполнитель может на собственное усмотрение разрешить вам
            время от времени предоставлять, загружать, публиковать или иначе
            предлагать компании Исполнителя, при использовании ее Услуг, контент
            в виде текста, аудиоматериалов, и/или видеоматериалов и информации,
            включая комментарии и обратную связь, связанные с Услугами,
            запросами на техническую поддержку и участием в конкурсах и
            рекламных акциях (
            <em>«Пользовательский контент»</em>
            ). Любой предоставленный вами Пользовательский контент остается
            вашей собственностью. Однако, предоставляя компании Исполнителя
            Пользовательский контент, вы выдаете компании Исполнителя
            международную, бессрочную, безвозвратную, передаваемую лицензию, без
            оплаты роялти, с правом сублицензировать, использовать, копировать,
            изменять, создавать производные работы, распределять, публично
            показывать, публично использовать, или каким-либо иным способом
            использовать такой Пользовательский контент во всех форматах и
            каналах распространения, известных в настоящее время или
            разработанных позднее (как в связи с Услугами и бизнесом компании
            Исполнителя, так и в связи с сайтами и услугами третьих лиц), без
            дальнейшего уведомления вас или получения от вас согласия, и без
            требования оплаты в ваш адрес или в адрес любого другого человека
            или юридического лица.
          </p>
          <p>Вы настоящим заявляете и гарантируете, что:</p>
          <ul>
            <li>
              Вы являетесь единственным и исключительным владельцем всего
              Пользовательского контента или у вас есть все права, лицензии,
              согласования и разрешения, чтобы предоставить компании Исполнитель
              лицензию на Пользовательский контент, как указано выше;
            </li>
            <li>
              ни Пользовательский контент, ни предоставление вами, загрузка,
              публикация или иной способ сделать доступным такой
              Пользовательский контент, ни использование компанией Исполнитель
              такого Пользовательского контента, как это разрешено настоящим
              документом, не ущемляют прав интеллектуальной собственности или
              авторских прав третьих лиц или права на гласность или частную
              жизнь, и не приведут к нарушению действующего законодательства или
              правовых норм.
            </li>
          </ul>
          <p>
            Вы соглашаетесь не предоставлять Пользовательский контент, который
            является дискредитирующим, клеветническим, ненавистным,
            насильственным, непристойным, порнографическим, незаконным или
            оскорбительным, как это определяет компания Исполнитель на свое
            собственное усмотрение, независимо от того, защищено ли законом
            предоставление таких материалов или нет. Компания Исполнитель может,
            но при этом не обязана рассматривать, контролировать или удалять
            Пользовательский контент на свое собственное усмотрение, в любое
            время и по любой причине, без отправки вам уведомления.
          </p>
          <h3>3.5. Доступ к сети и устройства.</h3>
          <p>
            Вы несете ответственность за получение доступа к сети передачи
            данных, необходимого для использования Услуг. При получении вами
            доступа или использовании Услуг посредством беспроводного устройства
            могут применяться тарифы вашей сети мобильной связи на СМС и на
            передачу данных, при этом вы несете ответственность за уплату таких
            тарифов. Вы несете ответственность за приобретение и обновление
            совместимой аппаратуры или устройств, необходимых для получения
            доступа к Услугам и Приложениям и для их использования, а также для
            получения любых обновлений к ним. Компания Исполнитель не
            гарантирует функционирование Услуг или какой-либо их части на
            какой-либо конкретной аппаратуре или устройствах. Кроме того, на
            Услуги могут повлиять сбои и задержки, вызванные работой сети
            Интернет и электронных средств связи.
          </p>
          <h2>
            4. Ответственность за причиненный вред стороннему поставщику
            (перевозчику).
          </h2>
          <h3>4.1. Сборы за ремонт или мойку.</h3>
          <p>
            При пользовании Услугами вы несете ответственность за стоимость
            ремонта после повреждения транспортного средства и имущества
            Стороннего поставщика или за расходы по необходимой мойке
            транспортного средства или имущества, превышающие степень
            естественного износа и необходимой мойки (
            <em>«Ремонт или мойка»</em>
            ). В случае, если Сторонний поставщик сообщает о потребности в
            Ремонте или мойке и такой запрос на Ремонт или мойку подтверждены
            компанией Исполнителем на ее разумном усмотрение, компания
            Исполнитель сохраняет за собой право обеспечить оплату разумных
            расходов на такой Ремонт или мойку от имени Стороннего поставщика,
            используя данные вашей учетной записи и обращением в страховую
            компанию или в правоохранительные органы. Такие суммы будет
            перечислены компанией Исполнителем соответствующему Стороннему
            поставщику и будут взысканы с вас.
          </p>
          <h2>5. Разрешение споров.</h2>
          <h3>5.1. Правовая оговорка</h3>
          <p>УСЛУГИ ОКАЗЫВАЮТСЯ ПО ПРИНЦИПУ «КАК ЕСТЬ».</p>
          <p>
            Компания Исполнитель отказывается от всех заявлений и гарантий,
            прямых, подразумеваемых или предусмотренным законом, явно не
            указанных в настоящих условиях, включая подразумеваемые гарантии
            товарного качества, пригодности для конкретной цели и отсутствия
            нарушений пав интеллектуальной собственности. Кроме того, компании
            Исполнитель не делает заявлений, не дает поручительств не
            предоставляет гарантий относительно надежности, своевременности,
            качества, пригодности или доступности услуг или что предоставление
            услуг будет осуществляться непрерывно или безотказно. Компания
            Исполнитель не гарантирует качества пригодности, безопасности или
            возможностей сторонних поставщиков. Вы самостоятельно соглашаетесь
            нести все риски, связанные с использованием вами услуг исключительно
            и в той максимальной степени, в которой это предусмотрено.
          </p>
          <h3>5.2. Ограничение ответственности</h3>
          <p>
            Компания Исполнитель не несет ответственности за косвенные,
            случайные, непредвиденные, фактические, штрафные или последующие
            убытки, включая потерянную прибыль, утерянные данные, телесные
            повреждения или материальный ущерб, связанный с услугами или иным
            образом обусловленные использованием услуг, даже если компанию
            Исполнителя предупредили о возможности таких убытков.
          </p>
          <p>
            Компания Исполнитель не несет ответственности за любые убытки,
            обязательства или ущерб, ставшие следствием использования вами услуг
            или ваших действий в расчете на услуги, либо отсутствия у вас
            возможности получить доступ к услугам или использовать услуги, или
            любой сделки или отношений между вами и любым сторонним поставщиком,
            даже если компания Исполнитель была предупреждена о возможности
            таких убытков.
          </p>
          <p>
            Компания Исполнитель не несет ответственности за задержку в
            предоставлении услуг или за неспособность оказать услуги по
            причинам, которые находятся вне разумного контроля со стороны
            компании Исполнителя. Вы признаете, что сторонние поставщики
            транспортных услуг, оказывающие транспортные услуги, запрошенные
            посредством определенных фирм по запросам, могут предложить услуги
            совместной доставки и могу не иметь профессиональных лицензий или
            разрешений. Ни при каких обстоятельствах компания Исполнитель не
            несет ответственности по отношению к вам в связи с оказываемыми
            услугами по всем убыткам, ущербу и основанием для риска.
          </p>
          <p>
            Услуги компании Исполнителя могут использоваться вами для запроса у
            стороннего поставщика и планирования перевозок, однако вы
            соглашаетесь, что у компании Исполнителя никаких обязательств и
            ответственности перед вами в отношении перевозок сторонними
            поставщиками, кроме случаев, явно предусмотренными настоящими
            условиями.
          </p>
          <h3>5.3. Гарантия возмещения убытков.</h3>
          <p>
            Вы соглашаетесь возместить убытки и освободить компанию Исполнитель
            и ее должностных лиц, директоров, служащих и агентов от любых исков,
            требований, убытков, обязательств и расходов (включая судебные
            издержки), ставших результатом, или возникших в связи с:
          </p>
          <ul>
            <li>
              использованием вами Услуг, либо услуг или товаров, полученных при
              использовании Услуг;
            </li>
            <li>нарушением вами любых положений настоящих Условий;</li>
            <li>
              использованием компанией Исполнитель вашего Пользовательского
              контента;
            </li>
            <li>
              нарушением вами прав любого третьего лица, включая права Сторонних
              поставщиков.
            </li>
          </ul>
          <h2>6. Применимое право.</h2>
          <p>
            Если иное не предусмотрено настоящими Условиями, настоящие Условия
            регулируются и толкуются исключительно в соответствии с
            законодательством Республики Беларусь.
          </p>
          <h2>7. Другие положения</h2>
          <h3>7.1. Претензии о нарушении авторского права.</h3>
          <p>
            Претензии о нарушении авторского права должны посылаться агенту,
            назначенному компанией Исполнителя. Пожалуйста, посетите
            веб-страницу компании Исполнитель по адресу
            {' '}
            <Link to="/legal/privacy">https://obs.by/legal/privacy</Link>
, чтобы
            узнать его адрес и получить дополнительную информацию.
          </p>
          <h3>7.2. Уведомления.</h3>
          <p>
            Компания Исполнитель может направлять уведомления посредством общего
            уведомления об Услугах, по электронной почте, указанной вами в вашей
            Учетной записи, или по почте на адрес, указанный вами в вашей
            Учетной записи. Вы можете направлять уведомления компании
            Исполнитель по следующему адресу:
            {' '}
            <a href="mailto:info.obs.by@gmail.com">info.obs.by@gmail.com</a>
          </p>
          <h3>7.3. Общие положения.</h3>
          <p>
            Вы не можете уступать или передавать настоящие Условия полностью или
            частично без предварительного письменного одобрения компанией
            Исполнитель. Вы даете свое согласие компании Исполнитель на то, что
            она может уступать или передавать настоящие Условия полностью или
            частично, в том числе:
          </p>
          <ul>
            <li>дочерней компании или аффилированному лицу;</li>
            <li>покупателю акций, бизнеса или активов компании Исполнитель;</li>
            <li>преемнику в результате слияния компаний.</li>
          </ul>
          <p>
            Отношения между вами, компанией Исполнителем и Сторонними
            поставщиками не создают никакого совместного предприятия,
            товарищества, агентства или отношений занятости, несмотря на наличие
            контракта между вами и компанией Исполнитель, или в результате
            использования вами Услуг.
          </p>
          <p>
            Если в силу закона какое-либо положение настоящих Условий будет
            признано незаконным, недействительным или лишённым исковой силы
            полностью или частично, такое положение или его часть будут
            считаться не входящими в настоящие Условия в соответствующем объёме,
            а законность, действительность и возможность приведения в исполнение
            остальной части настоящих Условий не будут нарушены. В таком случае
            стороны заменят незаконное, недействительное или лишённое исковой
            силы положение (его часть) положением (его частью), которое является
            законным, действительным и имеющим исковую силу и обладает
            максимально схожим действием относительно незаконного,
            недействительного или не имеющего исковой силы положения (его
            части), с учётом содержания и цели настоящих Условий. Настоящие
            Условия представляют собой всю полноту соглашения и договоренность
            сторон относительного его предмета. Они заменяют собой все
            предшествующие или существующие договорённости или обязательства
            касательно такого предмета. В настоящих Условиях слова «включая» и
            «включает» означают «включая, без ограничений».
          </p>
        </>
      )}

      {pathname === '/legal/privacy' && (
        <>
          <h1>ПОЛОЖЕНИЕ О КОНФИДЕНЦИАЛЬНОСТИ ПОЛЬЗОВАТЕЛЕЙ</h1>
          <span>Дата вступления в силу: 08.08.2017г.</span>
          <p>
            Мы собираем информацию о вас, когда вы используете наше мобильное
            приложение, веб-сайты и прочие онлайн-продукты и услуги (совместно
            именуемые «Услуги»), а также в ходе других ваших взаимодействий и
            коммуникаций с нами. Услуги предоставляются ООО «Центр цифровых
            транспортных технологий». и его аффилированными лицами в Республике
            Беларусь (здесь и далее совместно именуются «Исполнитель» или «мы»),
            и действие данного Положения о конфиденциальности распространяется
            на сведения, собираемые и используемые Исполнителем.
          </p>
          <h2>Сфера применения</h2>
          <p>
            Настоящее Положение о конфиденциальности («Положение»)
            распространяется на лиц, использующих наше приложение или Услуги в
            любой точке мира для заказа перевозки, доставки или иных заказных
            услуг («Пользователи»). Настоящее Положение не распространяется на
            сведения, собираемые нами у водителей, курьеров, партнерских
            транспортных компаний или иных лиц, использующих платформу
            Исполнителя по лицензии (совместно именуемых «Водители») или о таких
            лицах. Если вы пользуетесь Услугами и как Пользователь, и как
            Водитель, к различным типам вашего использования Услуг будут
            применяться соответствующие положения о конфиденциальности.
          </p>
          <h2>Сбор информации</h2>
          <h3>Информация, которую вы нам предоставляете</h3>
          <p>
            Мы собираем информацию, которую вы непосредственно нам
            предоставляете, например, когда вы создаете или изменяете свой
            аккаунт, заказываете услуги, обращаетесь к нам за помощью или иным
            образом общаетесь с нами. Эта информация может включать: имя, адрес
            электронной почты, номер телефона, почтовый адрес, фотографию
            профиля, способ оплаты, заказанные позиции (для услуг доставки),
            товарные накладные и прочую информацию, которую вы можете нам
            предоставить.
          </p>
          <h3>Данные, собираемые нами при использовании вами наших Услуг</h3>
          <p>
            Когда вы используете наши Услуги, мы собираем данные о вас в
            следующих основных категориях:
          </p>
          <ul>
            <li>
              <strong>Сведения о местонахождении:</strong>
              Когда вы используете Услуги для перевозки или доставки, мы
              собираем данные о вашем точном местонахождении во время поездки из
              приложения Исполнителя, используемого Водителем. Если вы разрешите
              приложению Исполнителя доступ к услугам локации через систему
              разрешения доступа, использующуюся вашей мобильной операционной
              системой («платформа»), мы также можем собирать точные данные о
              местонахождении вашего устройства, когда приложение будет работать
              в активном или фоновом режиме. Мы также можем определять ваше
              примерное местонахождение по вашему IP-адресу.
            </li>
            <li>
              <strong>Контактные данные: </strong>
              Если вы разрешите Исполнителю доступ к адресной книге на вашем
              устройстве через систему разрешения доступа, использующуюся вашей
              мобильной платформой, мы можем получить доступ и хранить имена и
              контактные данные из вашей адресной книги для облегчения
              социального взаимодействия через наши Услуги, а также в иных
              целях, описанных в данном Положении или в момент согласия или
              сбора данных.
            </li>
            <li>
              <strong>Сведения об операциях: </strong>
              Мы собираем сведения об операциях, связанных с вашим
              использованием Услуг, включая тип заказываемой услуги, дату и
              время оказания услуги, ее стоимость, расстояние и прочие
              соответствующие данные об операции.
            </li>
            <li>
              <strong>Данные об использовании и предпочтениях: </strong>
              Мы собираем информацию о том, как вы и посетители сайта
              взаимодействуют с нашими Услугами, о выбираемых предпочтениях и
              настройках. В некоторых случаях мы делаем это при помощи файлов
              cookie, пиксельных тегов и аналогичных технологий, создающих и
              обслуживающих уникальные идентификаторы.
            </li>
            <li>
              <strong>Сведения об устройстве: </strong>
              Мы можем собирать сведения о вашем мобильном устройстве, включая,
              например, модель устройства, операционную систему и версию,
              название программного обеспечения и файлов и их версию,
              предпочтительный язык, уникальный идентификатор устройства,
              рекламные идентификаторы, серийный номер, данные о перемещении
              устройства и сведения мобильной сети.
            </li>
            <li>
              <strong>Данные о звонках и SMS-сообщениях: </strong>
              Наши Услуги могут облегчать общение Пользователей с Водителями. В
              целях содействия этой услуге мы можем получить данные о звонках,
              включая дату и время звонка или SMS-сообщения, номера телефонов
              сторон и содержание SMS-сообщения.
            </li>
            <li>
              <strong>Данные журналов: </strong>
              Во время вашего взаимодействия с Услугами мы собираем данные
              журналов сервера, которые могут включать информацию об IP-адресе
              устройства, дате и времени входа, функциях приложения или
              просмотренных страницах, сбоях приложения или иных действиях
              системы, типе браузера, а также о сторонних сайтах или услугах,
              которыми вы пользовались до взаимодействия с нашими Услугами.
            </li>
          </ul>
          <h3>Важная информация о разрешениях платформы</h3>
          <p>
            У большинства мобильных платформ (iOS, Android и т.п.) есть
            определенные типы данных устройства, к которым приложения не могут
            получить доступ без вашего согласия. Эти платформы используют
            различные системы разрешения для получения вашего согласия.
            Платформа iOS предупредит вас, когда приложение Исполнитель в первый
            запросит разрешения на доступ к определенным данным, и позволит вам
            согласиться (или не согласиться) на этот запрос. Android-устройства
            уведомят вас о разрешениях, запрашиваемых приложением Исполнитель,
            перед первым использованием приложения вами, и ваше использование
            приложения будет означать ваше согласие. Иногда эти разрешения
            требуют более подробного разъяснения, чем приводится на платформах,
            и запрашиваемые нами разрешения со временем меняются.
          </p>
          <h3>Информация, собираемая нами из других источников</h3>
          <p>
            Мы также можем получать информацию из других источников и объединять
            ее с информацией, собираемой нами через наши Услуги. Например:
          </p>
          <ul>
            <li>
              Если вы решите привязать, создать или войти в свой аккаунт в любом
              из приложений Исполнителя через платежную систему (например,
              Google-кошелек) или социальную сеть (например, Facebook), или если
              вы работаете с отдельным приложением или веб-сайтом, использующим
              наш API (или API которого мы используем), мы можем получать
              информацию о вас или о ваших подключениях через этот сайт или
              приложение.
            </li>
            <li>
              Когда вы заказываете Услуги, наши Водители могут предоставлять нам
              рейтинг Пользователей после оказания вам услуг.
            </li>
            <li>
              Если вы взаимодействуете с нашими Услугами в другой роли,
              например, в качестве Водителя или пользователя других
              предоставленных нами приложений, мы можем объединять или связывать
              эту информацию с информацией, полученной нами о вас как о Водителе
              или пассажире.
            </li>
          </ul>
          <h2>Использование информации</h2>
          <p>
            Мы можем использовать информацию, полученную о вас, в следующих
            целях:
          </p>
          <ul>
            <li>
              Для предоставления, обслуживания и усовершенствования наших Услуг,
              в том числе, например, для облегчения оплаты, отправки квитанций,
              предоставления продуктов и услуг, которые вы заказываете, (и
              отправки соответствующей информации), разработки новых функций,
              оказания поддержки Пользователям и Водителям, разработки средств
              защиты, аутентификации пользователей, отправки обновлений по
              продуктам и служебных сообщений;
            </li>
            <li>
              Для осуществления внутренних операций, например, для
              предотвращения мошенничества и злоупотребления нашими Услугами;
              для устранения ошибок программного обеспечения и технических
              неисправностей; для проведения анализа данных, тестирования и
              исследований; а также для контроля и анализа использования и
              тенденций в работе;
            </li>
            <li>
              Для отправки сообщений или облегчения общения между вами и
              Водителем типа сообщения о примерном времени прибытия, или между
              вами и вашими контактами по вашему указанию в связи с
              использованием вами определенных функций, например, рекомендаций,
              приглашений, заявок на разделение тарифа или сообщения о примерном
              времени прибытия;
            </li>
            <li>
              Для отправки вам сообщений, которые, по-нашему мнению, могут
              заинтересовать вас, в том числе, о продуктах, услугах, рекламных
              акциях, новостях и мероприятиях Исполнителя и других компаний,
              когда это разрешено и в соответствии с действующим местным
              законодательством; а также для проведения конкурсов, розыгрышей
              или иных рекламных акций и предоставления соответствующих призов;
            </li>
            <li>
              Для персонализации и усовершенствования Услуг, в том числе для
              предоставления или рекомендации функций, контента, социальных
              контактов, советов или рекламы.
            </li>
          </ul>
          <p>
            Мы можем передавать информацию, описанную в настоящем Положении, а
            также обрабатывать и хранить ее в Республике Беларусь и в других
            странах, законы о защите данных которых могут быть менее строгими,
            чем законы страны, в которой вы проживаете. В этом случае мы будем
            принимать все разумные меры для защиты ваших персональных данных в
            соответствии с данным Положением.
          </p>
          <h2>Обмен информацией</h2>
          <p>
            Мы можем обмениваться информацией, собираемой нами о вас, как
            указано в настоящем Положении или как указано при сборе или обмене
            данными, в том числе следующим образом:
          </p>
          <h3>Через наши Услуги</h3>
          <p>Мы можем обмениваться вашими данными:</p>
          <ul>
            <li>
              С водителями, чтобы они могли оказывать заказанные вами Услуги.
              Например, мы предоставляем ваше имя, фотографию (если вы ее
              предоставили), средний рейтинг Пользователя по оценкам Водителей,
              а также место забора и/или высадки Водителям;
            </li>
            <li>
              С другими пассажирами, если вы пользуетесь сервисом обмена
              сообщениями, а также с другими людьми по вашему указанию,
              например, когда вы желаете сообщить свое примерное время прибытия
              или помочь разыскать/вернуть утерянную/найденную вами при поездке
              какую-либо вещь;
            </li>
            <li>
              С третьими лицами для оказания вам услуги, заказанной вами через
              партнеров или рекламное предложение от третьих лиц или от нас;
            </li>
            <li>
              С широкой публикой, если вы предоставите согласие на открытом
              форуме, например, в комментариях блога, сообщениях в социальных
              сетях или иных функциях наших Услуг, просматриваемых широкой
              публикой;
            </li>
            <li>
              С третьими лицами, с которыми вы разрешите нам делиться
              информацией, например, с другими приложениями или веб-сайтами,
              интегрированными с нашим API или Услугами, а также с приложениями
              или веб-сайтами с API или Услугами которых мы интегрированы.
            </li>
          </ul>
          <h3>Прочий важный обмен информацией</h3>
          <p>Мы можем обмениваться вашими данными:</p>
          <ul>
            <li>
              С дочерними и аффилированными компаниями Исполнитель,
              предоставляющими услуги или осуществляющими обработку данных по
              нашему поручению или в целях централизации данных и/или
              организационных целях;
            </li>
            <li>
              С поставщиками, консультантами, маркетинговыми партнерами и
              прочими поставщиками услуг, которым необходим доступ к такой
              информации для выполнения работ по нашему поручению;
            </li>
            <li>
              В ответ на информационный запрос компетентных органов, если мы
              уверены, что разглашение таких данных соответствует или требуется
              любым действующим законодательством, нормативно-правовым актом или
              судебным процессом;
            </li>
            <li>
              С сотрудниками правоохранительных органов, органами
              государственной власти и прочими третьими лицами, если мы уверены,
              что ваши действия не соответствуют нашим пользовательским
              соглашениям, Условиям обслуживания или политикам, а также для
              защиты прав, имущества или безопасности Исполнитель и иных лиц;
            </li>
            <li>
              В связи или в ходе переговоров о слиянии, приобретении активов
              компании, консолидации или реструктуризации, финансировании или
              приобретении всей или любой части нашего бизнеса другой компанией;
            </li>
            <li>
              Если мы по иным причинам уведомим вас и вы согласитесь на обмен
              информацией; и
            </li>
            <li>
              В обобщенной и/или анонимной форме, которая объективно не позволит
              идентифицировать вас.
            </li>
          </ul>
          <h2>Функции обмена информацией с социальными сетями</h2>
          <p>
            В Услуги могут быть интегрированы функции обмена информацией с
            социальными сетями и иные соответствующие инструменты, которые
            позволяют вам рассказывать о своих действиях в наших Услугах в
            других приложениях, на других сайтах, сетях и наоборот.
            Использование вами этих функций позволяет делиться информацией с
            вашими друзьями или публикой, в зависимости от настроек сервиса
            обмена информацией с социальными сетями. Просим вас ознакомиться с
            политиками конфиденциальности соответствующих социальных сетей,
            чтобы узнать подробнее о том, как они обрабатывают предоставленные
            вами данные или делятся ими.
          </p>
          <h2>Аналитические и рекламные услуги, предоставляемые другими</h2>
          <p>
            Мы можем разрешить другим лицам предоставлять вам услуги по оценке
            аудитории и анализу данных, демонстрации рекламы в Интернете от
            нашего имени, отслеживанию и сообщению об эффективности этой
            рекламы. Эти организации могут использовать файлы cookie, веб-маяки,
            пакеты средств разработки ПО и прочие технологии для идентификации
            вашего устройства при посещении нашего сайта и использования наших
            Услуг, а также при посещении других интернет-сайтов и сервисов.
          </p>
          <h2>Ваш выбор</h2>
          <h3>Данные аккаунта</h3>
          <p>
            Вы можете в любое время изменить данные аккаунта на сайте или в
            приложении. Чтобы удалить аккаунт, войдите на сайт obs.by со своими
            учетными данными либо откройте приложение. Затем выберите «Удалить
            аккаунт OBS» в разделе «Настройки». Обратите внимание: мы можем
            сохранять некоторые сведения о вас согласно требованиям
            законодательства или в рабочих целях (в рамках действующих законов).
            Например, если за вашим аккаунтом числится кредит или задолженность
            либо вы подозреваетесь в мошенничестве или нарушении наших Условий,
            ваши данные не будут удалены до полного выяснения обстоятельств
            вашего дела.
          </p>
          <h3>Права доступа</h3>
          <p>
            Исполнитель будет соблюдать индивидуальные запросы на доступ,
            исправление и/или удаление персональных данных, хранимых им в
            соответствии с действующим законодательством.
          </p>
          <h3>Сведения о местонахождении</h3>
          <p>
            Мы запрашиваем разрешение на получение нашим приложением данных о
            вашем точном местонахождении с вашего устройства через систему
            разрешений, используемую вашей мобильной операционной системой. Если
            вы изначально разрешите сбор этих данных, впоследствии вы сможете
            отключить эту функцию, изменив настройки местонахождения на своем
            мобильном устройстве. Однако это ограничит вашу возможность
            использовать определенные функции наших Услуг. Кроме того,
            отключение функции получения данных о точном местонахождении с
            вашего устройства нашим приложением не помешает нам собирать данные
            о вашем маршруте с устройства Водителя или определять ваше примерное
            местонахождение по вашему IP-адресу.
          </p>
          <h3>Контактные данные</h3>
          <p>
            Мы также можем запросить разрешение на сбор и синхронизацию
            контактных данных с вашего устройства нашим приложением через
            систему разрешений, используемую вашей мобильной операционной
            системой. Если вы изначально разрешите сбор этих данных,
            впоследствии пользователи iOS смогут отключить эту функцию, изменив
            настройки контактов на своем мобильном устройстве. В платформе
            Android такие настройки не предусмотрены.
          </p>
          <h3>Рекламные сообщения</h3>
          <p>
            Вы можете отказаться от получения рекламных сообщений от нас,
            воспользовавшись указаниями, приведенными в этих сообщениях. В
            случае вашего отказа мы все равно сможем присылать вам сообщения
            нерекламного характера, например, о вашем аккаунте, заказанных вами
            Услугах или текущих деловых отношениях.
          </p>
          <h2>Изменение Положения</h2>
          <p>
            Мы можем периодически вносить изменения в данное Положение. В случае
            внесения существенных изменений в отношении обращения с вашими
            персональными данными или в данное Положение мы уведомим вас об этом
            через Услуги или иным способом, например, по электронной почте.
            Продолжение использования Услуг после такого уведомления означает
            ваше согласие с изменениями. Рекомендуем вам периодически проверять
            данное Положение на предмет последних данных о наших принципах
            соблюдения конфиденциальности.
          </p>
        </>
      )}

      {pathname === '/legal/rules' && (
        <>
          <h1>ПРАВА И ОБЯЗАННОСТИ ПАССАЖИРОВ</h1>
          <h2>Пассажир имеет право:</h2>
          <ul>
            <li>
              приобрести проездной документ на проезд по маршруту, открытому для
              автомобильных перевозок пассажиров в регулярном сообщении, занять
              в автобусе место, указанное в проездном документе или
              автомобильным перевозчиком, либо свободное место и совершить
              поездку (кроме случаев, когда автомобильный перевозчик может
              отказать в перевозке);
            </li>
            <li>
              пользоваться льготами по оплате проезда на автомобильном
              транспорте, установленными законодательными актами или
              международными договорами Республики Беларусь;
            </li>
            <li>
              бесплатно провозить одно место маломерной ручной клади,
              длинномерные предметы размером до 151 см, а также инвалидную
              коляску, трости, костыли, ходунки и другие средства социальной
              реабилитации для личного пользования инвалидов, музыкальный
              инструмент в футляре, лыжи, клюшки, удочки, ружье в чехле, мелкий
              садовый инвентарь, велосипед в разобранном и упакованном виде,
              размеры которых в сумме измерений по длине, ширине и высоте не
              превышают 200 см, детский велосипед, санки и коляску;
            </li>
            <li>
              провозить за плату сверх нормы бесплатного провоза в автобусах без
              багажного отсека не более одного места маломерной ручной клади, а
              в автобусах с багажным отсеком – не более двух мест маломерного
              багажа или одного места среднемерного багажа;
            </li>
            <li>
              провозить за плату в багажном отсеке при наличии технической
              возможности и с согласия автомобильного перевозчика
              сверхнормативное количество маломерных и среднемерных мест багажа,
              а также крупномерные места багажа; провозить бесплатно во
              внутриреспубликанском сообщении специально обученную
              собаку-проводника, сопровождающую инвалида по зрению;
            </li>
            <li>
              провозить в регулярном сообщении с собой в счет разрешенных к
              перевозке мест багажа и (или) ручной клади бесплатно или платно (в
              зависимости от общего числа и мерности мест) мелких домашних
              животных (собак, кошек и других животных) и птиц в клетках,
              ящиках, мешках или сумках с водонепроницаемым адсорбирующим дном
              при условии ответственности пассажира за соблюдение мер
              безопасности и санитарных норм;
            </li>
            <li>
              провозить за плату в регулярном сообщении с согласия
              автомобильного перевозчика велосипед в неразобранном виде на
              специально оборудованных для этого местах;
            </li>
            <li>
              провозить в нерегулярном сообщении с согласия автомобильного
              перевозчика при выполнении условий обеспечения безопасности
              пассажиров и экипажа собаку крупной породы (высота в холке более
              50 см). При этом пассажир должен быть не моложе 16 лет, а если
              собака потенциально опасной породы, то пассажир должен быть
              совершеннолетним;
            </li>
            <li>
              совершить поездку по приобретенному ранее проездному документу без
              доплаты, если для автомобильной перевозки пассажиров используется
              автобус, стоимость проезда в котором выше стоимости приобретенного
              проездного документа;
            </li>
            <li>
              отказаться от поездки с получением уплаченной за нее суммы либо
              совершить поездку, получив разницу между уплаченной суммой и
              фактически причитающейся платой за проезд, если автомобильный
              перевозчик использует автобус, в котором стоимость проезда ниже
              стоимости приобретенного проездного документа;
            </li>
            <li>
              сдать проездной документ до начала поездки и получить в
              установленном порядке полностью или частично уплаченную стоимость
              проезда при выполнении пригородных, междугородных автомобильных
              перевозок пассажиров в регулярном сообщении;
            </li>
            <li>
              объявлять ценность багажа при его автомобильной перевозке или
              хранении не выше его реальной стоимости с предъявлением его
              содержимого;
            </li>
            <li>
              обратиться к администрации пассажирского терминала с письменным
              заявлением о вскрытии ячейки автоматической камеры хранения;
            </li>
            <li>
              получать своевременную и точную информацию об автомобильном
              перевозчике, диспетчере автомобильной перевозки пассажиров в
              нерегулярном сообщении, диспетчере такси, о расписании движения,
              стоимости проезда и других условиях автомобильных перевозок
              пассажиров;
            </li>
            <li>
              пользоваться по собственному выбору дополнительными услугами,
              предоставляемыми автомобильными перевозчиками и владельцами
              пассажирских терминалов;
            </li>
            <li>
              вносить заказчику автомобильных перевозок пассажиров в регулярном
              сообщении или оператору автомобильных перевозок пассажиров либо
              автомобильному перевозчику в регулярном сообщении предложения о
              совершенствовании автомобильных перевозок, работы пассажирских
              терминалов, повышении качества обслуживания пассажиров,
              безопасности автомобильных перевозок пассажиров, эффективном
              использовании автобусов;
            </li>
            <li>
              требовать от автомобильного перевозчика выполнения условий
              договора автомобильной перевозки пассажира и настоящих Правил;
            </li>
            <li>
              получить в соответствии с законодательством возмещение за вред,
              причиненный его жизни, здоровью или имуществу при выполнении
              автомобильной перевозки пассажиров.
            </li>
            <p>
              Пассажир может иметь иные права, установленные настоящими
              Правилами, иными актами законодательства и договором автомобильной
              перевозки пассажира.
            </p>
          </ul>
          <h2>Пассажир обязан:</h2>
          <ul>
            <li>
              соблюдать требования настоящих Правил, Правил дорожного движения и
              иных нормативных правовых актов, а также выполнять условия
              договора автомобильной перевозки пассажира, заключенного с
              автомобильным перевозчиком;
            </li>
            <li>
              своевременно приобретать проездной документ и в полном объеме
              оплачивать проезд, если иное не предусмотрено законодательными
              актами и международными договорами Республики Беларусь, у
              водителя, в кассе продажи проездных документов или других местах –
              до начала поездки, у кондуктора (при его наличии) – до следующего
              остановочного пункта маршрута;
            </li>
            <li>
              при отсутствии проездного документа и намерении приобрести его у
              водителя входить в автобус через переднюю дверь;
            </li>
            <li>
              при выполнении городских автомобильных перевозок пассажиров в
              регулярном сообщении в автобусе, оборудованном компостерами, для
              подтверждения оплаты проезда при посадке в автобус отметить
              посредством компостера билет на одну поездку;
            </li>
            <li>
              при выполнении городских автомобильных перевозок пассажиров в
              регулярном сообщении в автобусе, оборудованном автоматизированной
              системой оплаты и контроля оплаты проезда, для подтверждения
              оплаты проезда при посадке в автобус приложить электронный билет
              многоразового пользования к валидатору;
            </li>
            <li>
              при выполнении пригородных автомобильных перевозок пассажиров в
              регулярном сообщении в автобусе, оборудованном автоматизированной
              системой оплаты и контроля оплаты проезда, для подтверждения и
              расчета оплаты проезда при посадке в автобус и высадке из него
              приложить электронный билет многоразового пользования к
              валидатору; оплатить в установленных случаях провоз багажа и (или)
              ручной клади (иметь багажную квитанцию или подтверждение оплаты
              провоза ручной клади);
            </li>
            <li>
              оплатить услуги, предоставленные в транспортном средстве или на
              пассажирском терминале;
            </li>
            <li>
              проверять деньги и проездные документы, не отходя от кассы продажи
              проездных документов либо от кондуктора или водителя (при покупке
              проездных документов в салоне автобуса);
            </li>
            <li>
              иметь и сохранять до конца поездки проездные документы и багажные
              квитанции, иметь при себе оригиналы документов, подтверждающих
              право на льготы по оплате проезда на автомобильном транспорте, и
              предъявлять их по требованию работников контрольно-ревизорской
              службы и членов экипажа транспортного средства (водитель,
              кондуктор);
            </li>
            <li>
              при проверке полноты оплаты проезда в автобусе, оборудованном
              автоматизированной системой оплаты и контроля оплаты проезда,
              приложить электронный билет для проверки к терминалу работника
              контрольно-ревизорской службы;
            </li>
            <li>
              обеспечить сопровождение при городских и пригородных автомобильных
              перевозках ребенка до 7 лет, при междугородных перевозках – до 14
              лет и при международных – до 16 лет;
            </li>
            <li>
              производить размещение ручной клади в салоне транспортного
              средства и крупномерного багажа в багажном отсеке в соответствии с
              указаниями автомобильного перевозчика;
            </li>
            <li>
              по прибытии в пункт назначения либо на конечный остановочный пункт
              маршрута освободить салон транспортного средства и получить багаж;
              осуществлять посадку в транспортное средство и высадку из него в
              соответствии с требованиями настоящих Правил;
            </li>
            <li>занимать место, указанное в проездном документе;</li>
            <li>
              уступать при городской перевозке пассажиров в регулярном сообщении
              специально обозначенные места для сидения пассажирам с детьми
              дошкольного возраста, беременным женщинам, инвалидам и лицам
              пожилого возраста;
            </li>
            <li>
              держаться за поручень во время движения автобуса при проезде в
              салоне автобуса стоя;
            </li>
            <li>
              ожидать транспортное средство на посадочной площадке (при ее
              отсутствии – на тротуаре) не ближе 0,3 м от края проезжей части,
              после выхода из транспортного средства не находиться на краю
              посадочной площадки или на краю тротуара;
            </li>
            <li>
              выходить на проезжую часть, если посадка с посадочной площадки или
              тротуара невозможна, только после остановки транспортного
              средства;
            </li>
            <li>
              не причинять своими действиями вред пассажирам, ущерб их багажу и
              ручной клади, а также салону и багажному отсеку транспортного
              средства;
            </li>
            <li>
              сообщать водителю об обнаруженных в салоне транспортного средства
              вещах и документах, оставленных без присмотра;
            </li>
            <li>
              при автомобильной перевозке собаки или кошки обеспечить наличие
              жетона и регистрационного свидетельства животного с отметкой
              ветеринарного врача о вакцинации против бешенства, а в
              установленных случаях – ветеринарного свидетельства или
              ветеринарной справки;
            </li>
            <li>
              соблюдать санитарные нормы при автомобильной перевозке домашних
              животных (собак, кошек и других животных) и птиц;
            </li>
            <li>
              иметь надлежащим образом оформленные документы на право
              пересечения границ соответствующих государств при осуществлении
              международной поездки;
            </li>
            <li>
              соблюдать установленные правила пересечения границы государства,
              по территории которого выполняется международная автомобильная
              перевозка пассажиров, присутствовать во время таможенного осмотра
              багажа и другого его имущества, а также при отборе контролирующими
              органами для экспресс-анализа образцов и проб принадлежащих ему
              веществ и материалов;
            </li>
            <li>
              {' '}
              возместить в установленном законодательством порядке
              автомобильному перевозчику и (или) другим пассажирам ущерб,
              причиненный собственными неосторожными или умышленными действиями;
            </li>
            <li>
              при утере багажной квитанции или документа на хранение вещей в
              камере хранения подать лицу, ответственному за вещи, письменное
              заявление с указанием вида упаковки и описи вещей;
            </li>
            <li>
              не создавать препятствий для передвижения пассажиров по салону
              автобуса при размещении ручной клади в проходах и на площадках у
              дверей автобуса, а также не размещать ручную кладь на сиденьях
              транспортных средств;
            </li>
            <li>
              не создавать своими действиями, состоянием и поведением неудобств
              другим пассажирам, водителям и кондукторам;
            </li>
            <li>
              не пользоваться аварийным оборудованием транспортного средства в
              ситуации, не угрожающей жизни и здоровью людей;
            </li>
            <li>не засорять салон транспортного средства;</li>
            <li>
              не провозить запрещенные к перевозке предметы в багаже и (или)
              ручной клади;
            </li>
            <li>
              не провозить в салоне места среднемерной (при наличии багажного
              отсека) и крупномерной ручной клади (кроме автомобильных перевозок
              пассажиров автомобилями- такси);
            </li>
            <li>
              не выбрасывать вещи или предметы в окна транспортного средства;
            </li>
            <li>
              не входить в автобус и не выходить из него после объявления о
              закрытии дверей;
            </li>
            <li>
              не выходить из транспортного средства в случае его аварийной
              остановки без разрешения водителя, а также в ситуации, не
              угрожающей жизни и здоровью пассажиров;
            </li>
            <li>
              не открывать самостоятельно двери автобуса, не мешать открытию и
              закрытию дверей, а также не вмешиваться в управление автобусом, за
              исключением ситуации, направленной на предотвращение несчастного
              случая по причине резкого ухудшения самочувствия водителя,
              препятствующего выполнению его обязанностей;
            </li>
            <li>не опираться на двери автобуса;</li>
            <li>
              не входить в автобус и не перемещаться по его салону в роликовых
              коньках, а также на скейтбордах, самокатах и иных подобных
              средствах передвижения;
            </li>
            <li>
              при входе в автобус снимать наплечные сумки (рюкзаки) со спины и
              перевозить их в руках;
            </li>
            <li>не пользоваться аэрозолями в салоне транспортного средства;</li>
            <li>
              не находиться в одежде или с ручной кладью, загрязняющей одежду
              пассажиров или салон транспортного средства;
            </li>
            <li>не высовываться в оконные проемы транспортного средства;</li>
            <li>
              не отвлекать водителя от управления транспортным средством и не
              мешать ему во время движения;
            </li>
            <li>
              выполнять иные обязанности в соответствии с законодательством.
            </li>
          </ul>
          <p>
            Пассажир несет ответственность за нарушение требований настоящих
            Правил, нанесение материального ущерба автомобильному перевозчику,
            заказчику автомобильных перевозок пассажиров или другим участникам
            автомобильной перевозки пассажиров.
          </p>
        </>
      )}

      {pathname === '/legal/payment-rules' && (
        <>
          <h1>Информация о платежах банковской картой</h1>
          <p>
            При помощи интернет-ресурса OBS.BY возможно оплатить услугу за
            перевозку пассажира после заранее забронированного места при помощи
            банковской пластиковой карточки.
          </p>
          <p>
            В случае если пассажирам выбрана возможность оплаты при помощи
            банковской карты, то платежи осуществляются в соответствии с
            законодательными актами Республики Беларусь регулирующими, такой
            порядок платежей.
          </p>
          <p>
            ООО «Центр цифровых транспортных технологий» не является организаций
            которая принимает на свой расчётный счёт платежи за оплату услуг.
            Пассажир осуществляет оплату услуг на расчетный счёт перевозчика,
            который указан в бронировании пассажира, по определённому маршруту.
          </p>
          <p>
            В случае отмены рейса со стороны перевозчика по обстоятельствам,
            которые не зависят от пассажира, пассажиру возвращается 100%
            оплаченных средств.
          </p>
          <p>
            В случае если пассажиром произведена отмена бронирования, то возврат
            производится в следующем порядке, согласно Постановлению Совета
            Министров Республики Беларусь от 31.06.2008 года № 972, утверждающих
            Правила перевозки пассажиров (в редакции Постановления Совета
            Министров Республики Беларусь от 30 декабря 2020 г. № 772):
          </p>
          <ul>
            <li>
              п. 56. Лицо, имеющее билет (электронный билет) на одну пригородную
              или междугородную поездку в регулярном сообщении, может не позднее
              чем за два часа до отправления автобуса возвратить билет
              (электронный билет) и получить обратно уплаченную стоимость
              проезда. Стоимость услуги по продаже электронного билета, плата за
              предварительную продажу билета и (или) бронирование места для
              проезда, а также за доставку билета на дом не возвращается.
            </li>
            <li>
              п. 57. При возврате билета (электронного билета) на одну
              пригородную или междугородную поездку в регулярном сообщении менее
              чем за два часа, но не менее чем за 15 минут до отправления
              автобуса лицу, сдающему билет (электронный билет), возвращается
              стоимость проезда за вычетом 15 процентов.
            </li>
            <li>
              п. 58. Если гражданин, имеющий билет (электронный билет) на одну
              пригородную или междугородную поездку в регулярном сообщении,
              опоздал ко времени отправления автобуса или отказался от поездки
              меньше чем за 15 минут до отправления, он имеет право в течение 2
              часов, а в случае болезни или несчастного случая в течение 3 суток
              со времени отправления автобуса в рейс переоформить билет
              (электронный билет) на следующий рейс того же маршрута или
              направления с доплатой 25 процентов стоимости проезда или получить
              уплаченную стоимость проезда за вычетом 25 процентов.
            </li>
          </ul>
          <p>
            Плата за предварительную продажу билета и (или) бронирование места
            для проезда составляет 7% от суммы.
          </p>
          <p>Безопасность платежей обеспечивается следующим образом:</p>
          <ul>
            <li>
              В случае оплаты посредством банковской карты, пассажир будет
              переадресовываться на авторизационные страницы для последующего
              ввода реквизитов карточек, а в случае возможности для
              осуществления аутентификации держателя карточки с использованием
              методов Verified By Visa и или MasterCard SecureCode, Интернет
              Пароль БЕЛКАРТ.
            </li>
            <li>
              Доступ к авторизационным страницам осуществляется с использованием
              протокола, обеспечивающего безопасную передачу данных в интернете
              SSL
            </li>
          </ul>
        </>
      )}
    </Container>
  );
};

Legal.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Legal;
