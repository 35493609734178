import styled from 'styled-components';

const Container = styled.div`
  max-width: 1040px;
  margin: 0 auto;
`;

const Tickets = styled.div`
  position: relative;
  min-height: 100px;
  margin-top: 75px;
  @media (max-width: 575px) {
    margin-top: 35px;
  }
`;

const TicketWrapper = styled.div`
  display: flex;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 35px;
  }
  > div:first-child {
    flex: 1;
  }

  @media (max-width: 768px){
    flex-direction: column;
    align-items: stretch;
    > div:first-child {
      margin-bottom: 20px;
    }
  }
`;

const TicketInfo = styled.div`
  margin-left: 15px;
  width: 235px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  button {
    margin-bottom: 30px;
  }
  span {
    font-weight: 600;
    font-size: 24px;
    color: ${(props) => (props.amount > 0 ? '#2DCB33' : '#9A9A9A')};
  }

  @media (max-width: 768px){
    width: auto;
    button {
      margin-bottom: 10px;
    }
    span {
      font-size: 16px;
    }
  }
`;

const NoData = styled.div`
  text-align: center;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
  
  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

export {
  Container,
  Tickets,
  TicketWrapper,
  TicketInfo,
  NoData,
};
