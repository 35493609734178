import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 40px;
  input {
    width: 100%;
    padding: 0px;
    border: none;
    margin-bottom: 0px;
    &:placeholder {
      color: red !important;
    }
  }
  div:first-child {
    margin-right: 20px;
  }
  div:last-child {
    flex: 1;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export { Container };
