import React from 'react';
import { Helmet } from 'react-helmet/es/Helmet';

const NotFound = () => (
  <>
    <Helmet
      meta={[
        {
          name: 'prerender-status-code',
          content: '404',
        },
      ]}
    />
    {'Not Found'}
  </>
);

export default NotFound;
