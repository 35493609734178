import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const BaseInput = styled.input`
  font-family: 'Montserrat';
  font-weight: 400;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-bottom: 2px solid #E5E5E5;
  outline-style: none;
  box-sizing: border-box;
  width: 100%;
  
  ${(props) => props.errors && 'margin-bottom: 5px'}

  ${(props) => {
    switch (props.size) {
      case 'medium':
        return css`
          font-size: 24px;
        `;
      case 'small':
        return css`
          font-size: 18px;
        `;
      default:
        return null;
    }
  }}
  
  &:placeholder {
    color: #9A9A9A;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
  
  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

const Error = styled.div`
  text-align: center;
  color: #FF4040;
`;

export { Container, BaseInput, Error };
