import styled, { css } from 'styled-components';

import { getColor } from './helpers';

const BaseButton = styled.button`
  background-color: #ffffff;
  color: ${(props) => getColor(props.color, 'default')};
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 21px;
  padding: ${(props) => (props.loading === 'true' ? '11px 13px 11px 53px' : '11px 33px')};
  border: 4px solid ${(props) => getColor(props.color, 'default')};
  border-radius: 100px;
  outline: none;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > div {
    position: absolute;
    left: 0px;
  }

  :disabled {
    border-color: #9A9A9A;
    color: #9A9A9A;
    :hover {
      cursor: auto;
      border-color: #9A9A9A;
      color: #9A9A9A;
    }
  }

  :hover {
    cursor: pointer;
    border-color: ${(props) => getColor(props.color, 'hover')};;
    color: ${(props) => getColor(props.color, 'hover')};;
  }

  :active {
    cursor: pointer;
    border-color: ${(props) => getColor(props.color, 'active')};;
    color: ${(props) => getColor(props.color, 'active')};;
  }

  ${(props) => props.primary && css`
    background-color: ${() => getColor(props.color, 'default')};;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 24px;
    padding: ${() => (props.loading === 'true' ? '15px 20px 15px 55px' : '15px 30px')};
    border: none;

    :disabled {
      background-color: #9A9A9A;
      color: #ffffff;
      :hover {
        cursor: auto;
        background-color: #9A9A9A;
        color: #ffffff;
      }
    }

    :hover {
      cursor: pointer;
      background-color: ${() => getColor(props.color, 'hover')};;
      color: #ffffff;
    }

    :active {
      cursor: pointer;
      background-color: #${() => getColor(props.color, 'active')};;
      color: #ffffff;
    }
  `}

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

export { BaseButton };
