import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAsync } from 'react-async';
import LogRocket from 'logrocket';

import api from 'utils/api';
import { useStore } from 'utils/store';
import { MainLayout } from 'layouts';
import { ErrorBoundary, RouteWithLayout, ScrollToTop } from 'components';
import { LoadingIndicator } from 'components/shared';
import {
  BookingPage,
  CarriersPage,
  ContactsPage,
  LegalPage,
  LoginPage,
  MainPage,
  NotFoundPage,
  ProfilePage,
  RegistrationPage,
  SearchPage,
  SettingsPage,
} from 'pages';
import { Helmet } from 'react-helmet/es/Helmet';
import { YMInitializer } from 'react-yandex-metrika';

const check = () => api.post('/v2/clients/check');

const App = () => {
  const [store, setStore] = useStore();

  const { isLoading, run } = useAsync({
    deferFn: check,
    onResolve: (user) => {
      setStore({ user });
      localStorage.setItem('token', user.token);
      LogRocket.identify(user.phone, {
        name: user.name,
        surname: user.surname,
      });
    },
    onReject: (error) => {
      if (error.responce) {
        localStorage.removeItem('token');
      }
    },
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      run();
    } else {
      setStore({});
    }
  }, []);

  if (isLoading) {
    return <LoadingIndicator overlay />;
  }

  if (store) {
    return (
      <ErrorBoundary>
        <Helmet
          meta={[
            {
              name: 'description',
              content:
                'Online Bus Station - это сервис, который позволяет легко и просто найти и заказать место в маршрутном такси регулярного сообщения',
            },
          ]}
        />
        <YMInitializer
          accounts={[66530566]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            trackHash: true,
          }}
        />
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={MainPage} />
            <RouteWithLayout
              path="/login"
              layout={MainLayout}
              component={LoginPage}
              title="Вход"
            />
            <RouteWithLayout
              path="/registration"
              layout={MainLayout}
              component={RegistrationPage}
              title="Регистрация"
            />
            <RouteWithLayout
              path="/search"
              layout={MainLayout}
              component={SearchPage}
              title="Поиск"
            />
            <RouteWithLayout
              path="/booking"
              layout={MainLayout}
              component={BookingPage}
              title="Бронирование"
            />
            <Route path="/profile" component={ProfilePage} />
            <RouteWithLayout
              path="/settings"
              layout={MainLayout}
              component={SettingsPage}
              title="Настройки"
            />
            <RouteWithLayout
              path="/carriers"
              layout={MainLayout}
              component={CarriersPage}
              title="Перевозчики"
            />
            <RouteWithLayout
              path="/contacts"
              layout={MainLayout}
              component={ContactsPage}
              title="Диспетчерские службы"
            />
            <RouteWithLayout
              path="/legal/terms"
              layout={MainLayout}
              component={LegalPage}
              title="Условия и положения"
            />
            <RouteWithLayout
              path="/legal/privacy"
              layout={MainLayout}
              component={LegalPage}
              title="Политика конфиденциальности"
            />
            <RouteWithLayout
              path="/legal/rules"
              layout={MainLayout}
              component={LegalPage}
              title="Права и обязанности пассажиров"
            />
            <RouteWithLayout
              path="/legal/payment-rules"
              layout={MainLayout}
              component={LegalPage}
              title="Правила оплаты"
            />
            <Route component={NotFoundPage} />
          </Switch>
        </ScrollToTop>
      </ErrorBoundary>
    );
  }

  return null;
};

export default App;
