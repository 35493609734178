import React from 'react';
import PropTypes from 'prop-types';

import { FindBusForm } from 'containers';

import {
  Container,
} from './styles';

const FindBus = ({ innerRef }) => (
  <Container ref={innerRef} id="findBusForm">
    <h2>Подобрать рейс</h2>
    <FindBusForm />
  </Container>
);

FindBus.propTypes = {
  innerRef: PropTypes.object.isRequired,
};

export default FindBus;
