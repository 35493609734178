import React from 'react';
import PropTypes from 'prop-types';

import routeBlue from 'images/routeBlue.svg';
import routePurple from 'images/routePurple.svg';
import routeGray from 'images/routeGray.svg';
import priceBlue from 'images/priceBlue.svg';
import pricePurple from 'images/pricePurple.svg';
import priceGray from 'images/priceGray.svg';

import code from 'images/code.svg';

import {
  Container,
  Content,
} from './styles';

const getImage = (key) => {
  switch (key) {
    case 'routeblue':
      return routeBlue;
    case 'routepurple':
      return routePurple;
    case 'routegray':
      return routeGray;
    case 'priceblue':
      return priceBlue;
    case 'pricepurple':
      return pricePurple;
    case 'pricegray':
      return priceGray;
    default:
      return null;
  }
};

const Ticket = ({
  header,
  from,
  to,
  price,
  color,
}) => (
  <Container>
    <h3>{header}</h3>
    <Content>
      <img src={getImage(`route${color}`)} alt="route" />
      <div>
        <span>
          {`${from.city}, `}
          <b>{from.time}</b>
        </span>
        <span>{from.stop}</span>
      </div>
      <div>
        <span>
          {`${to.city}, `}
          <b>{to.time}</b>
        </span>
        <span>{to.stop}</span>
      </div>
    </Content>
    {price
      && (
      <>
        <span>{price}</span>
        <img src={getImage(`price${color}`)} alt="price" />
      </>
      )}
    <img src={code} alt="code" />
  </Container>
);

Ticket.propTypes = {
  header: PropTypes.string.isRequired,
  from: PropTypes.shape({
    city: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    stop: PropTypes.string.isRequired,
  }).isRequired,
  to: PropTypes.shape({
    city: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    stop: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['blue', 'purple', 'gray']),
};

Ticket.defaultProps = {
  color: 'blue',
};

export default Ticket;
