import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

class ErrorBoundary extends PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = { error: false };

  componentDidCatch(err, errInfo) {
    this.setState({
      error: true,
    });
    console.error(errInfo.componentStack);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <Container>
          <h1>
            Что-то пошло не так, повторите попытку позже.
          </h1>
        </Container>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
