import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
   html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: Montserrat, Roboto, Segoe UI, Arial, sans-serif;
    font-size: 18px;
    min-width: 320px;
    overflow: hidden;
  }
  
  #root {
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  div {
    box-sizing: border-box;
  }

  a {
    color: #6C00D8;
    text-decoration: none;
  }

  b {
    font-weight: 500;
  }

  h2 {
    font-weight: 500;
    font-size: 36px;
    text-transform: uppercase;
    margin: 0px;
  }

  h3 {
    font-weight: 500;
    font-size: 24px;
    margin: 0px;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 28px;
    }
  }
  
  @media (max-width: 575px) {
    h2 {
      font-size: 24px;
    }
  }
`;
