import styled from 'styled-components';

const Container = styled.div`
  background: #6c00d8;
`;

const Content = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  padding: 45px 20px 45px 20px;

  @media (max-width: 575px) {
    padding: 25px 10px 25px 10px;
  }
`;

const Links = styled.div`
  margin-bottom: 24px;
  > a {
    display: block;
    font-size: 18px;
    color: #ffffff;
    text-decoration: none;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

const Copyright = styled.div`
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 24px;
`;

const Info = styled.div`
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
`;

export {
  Container, Content, Links, Copyright, Info,
};
