import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Select,
  Input,
  Modal,
  Icon,
} from 'components/shared';
import { parseDate, formatDate } from 'components/shared/Datepicker/helpers';

import {
  Container,
  Info,
  Fields,
  Divider,
  Amount,
  Label,
  Control,
  ModalFooter,
} from './styles';

const validationSchema = Yup.object().shape({
  from: Yup.object().required(),
  to: Yup.object().required(),
  info: Yup.string(),
});

const getInitialValues = (transfer, user, reservation) => ({
  client: user.phone,
  transfer: transfer.id,
  from: reservation.from ? reservation.from : '',
  to: reservation.to ? reservation.to : '',
  amount: reservation.amount ? reservation.amount : 1,
  info: reservation.info ? reservation.info : '',
  createdBy: { role: 'web-client', phone: user.phone },
});

const Booking = ({
  transfer,
  stops,
  user,
  reservation,
  loading,
  book,
  message,
  setMessage,
  toAuth,
}) => (
  <Container>
    <Info>
      <h3>Информация о рейсе</h3>
      <div>
        <span>Когда</span>
        <span>{formatDate(parseDate(transfer.date, 'yyyy-MM-dd'), 'dd.MM.yyyy')}</span>
      </div>
      <div>
        <span>Отправление</span>
        <span>{`${transfer.from.time} ${transfer.from.name} (${transfer.from.city.name})`}</span>
      </div>
      <div>
        <span>Прибытие</span>
        <span>{`${transfer.to.time} ${transfer.to.name} (${transfer.to.city.name})`}</span>
      </div>
      <div>
        <span>Маршрут</span>
        <span>{`${transfer.route.number} ${transfer.route.name}`}</span>
      </div>
      <div>
        <span>Перевозчик</span>
        <Link to="/carriers">{transfer.carrier.name}</Link>
      </div>
    </Info>
    <Formik
      validationSchema={validationSchema}
      initialValues={getInitialValues(transfer, user, reservation)}
      onSubmit={(values) => {
        if (user.phone) {
          book({
            ...values,
            from: values.from.id,
            to: values.to.id,
          });
        } else {
          setMessage({
            header: 'Внимание',
            text: 'Для завершения бронирования необходимо войти/зарегистрироваться',
            footer: true,
          });
        }
      }}
      render={(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <Fields>
            <Field
              name="from"
              component={({ field: { value, name, onChange } }) => (
                <Select
                  placeholder="Остановка отправления"
                  items={stops.map((stop) => stop.from)}
                  value={value}
                  onSelect={(newValue) => {
                    onChange({ target: { name, value: newValue } });
                    onChange({ target: { name: 'to', value: null } });
                  }}
                  labelKey={(item) => `${item.time} ${item.name} (${item.city})`}
                  valueKey="id"
                  searchable
                  searchKey="name"
                  searchPlaceholder="Поиск"
                  noResultsPlaceholder="Нет результатов"
                  icon="point"
                />
              )}
            />
            <Divider />
            <Field
              name="to"
              component={({ field: { value, name, onChange } }) => (
                <Select
                  placeholder="Остановка прибытия"
                  items={
                      formProps.values.from
                        ? stops.find((stop) => stop.from.id === formProps.values.from.id).to
                        : []
                    }
                  value={value}
                  onSelect={(newValue) => onChange({ target: { name, value: newValue } })}
                  labelKey={(item) => `${item.time} ${item.name} (${item.city})`}
                  valueKey="id"
                  searchable
                  searchKey="name"
                  searchPlaceholder="Поиск"
                  noResultsPlaceholder="Нет результатов"
                  icon="point"
                  disabled={!formProps.values.from}
                />
              )}
            />
            <Divider />
            <Field
              name="amount"
              component={({ field: { value, name, onChange } }) => (
                <Amount>
                  <Label>
                    <Icon type="seat" />
                    Количество мест
                  </Label>
                  <div>
                    <Control
                      active={value === 1}
                      onClick={() => onChange({ target: { name, value: 1 } })}
                    >
                      Одно
                    </Control>
                    <Control
                      active={value === 2}
                      onClick={() => onChange({ target: { name, value: 2 } })}
                    >
                      Два
                    </Control>
                  </div>
                </Amount>
              )}
            />
            <Divider />
            <Field
              name="info"
              placeholder="Информация для водителя"
              component={Input}
            />
          </Fields>
          <Button
            primary
            loading={loading}
            disabled={
                !formProps.values.from
                || !formProps.values.to
                || !formProps.values.amount
              }
          >
            Забронировать
          </Button>
          <Modal
            opened={!!message.text}
            header={message.header}
            onClose={() => setMessage({})}
            footer={message.footer && (
            <ModalFooter>
              <Button onClick={() => toAuth(formProps.values)}>OK</Button>
            </ModalFooter>
            )}
          >
            {message.text}
          </Modal>
        </form>
      )}
    />
  </Container>
);

Booking.propTypes = {
  transfer: PropTypes.object.isRequired,
  stops: PropTypes.array.isRequired,
  user: PropTypes.object,
  reservation: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  book: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  setMessage: PropTypes.func.isRequired,
  toAuth: PropTypes.func.isRequired,
};

Booking.defaultProps = {
  user: {},
  reservation: {},
};

export default Booking;
