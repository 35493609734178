import React from 'react';
import PropTypes from 'prop-types';

import { LoadingIndicator } from 'components/shared';

import { getColor } from './helpers';
import { BaseButton } from './styles';

const Button = ({
  loading,
  children,
  primary,
  color,
  ...props
}) => (
  <BaseButton {...props} loading={loading.toString()} primary={primary} color={color}>
    {loading && <LoadingIndicator scale={0.5} color={primary ? '#fff' : getColor(color, 'default')} />}
    {children}
  </BaseButton>
);

Button.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  primary: PropTypes.bool,
  color: PropTypes.oneOf(['orange', 'red']),
};

Button.defaultProps = {
  loading: false,
  children: null,
  primary: false,
  color: 'orange',
};

export default Button;
