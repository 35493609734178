import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { Modal, Input, Button } from 'components/shared';

import { FormContainer, FormHeader } from './styles';

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(
      /\+375(29|33|44|25)\d{7}/,
      'Пожалуйста введите номер телефона в формате +375XXYYYYYYY',
    )
    .required('Пожалуйста введите номер телефона'),
  code: Yup.string().matches(
    /^\d{4}$/,
    'Пожалуйста введите код из СМС в формате XXXX',
  ),
});

const Login = ({
  isAuthentication,
  isAuthorization,
  message,
  authenticate,
  authorize,
  setMessage,
}) => (
  <>
    <FormContainer>
      <FormHeader>Вход</FormHeader>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ phone: '', code: '' }}
        onSubmit={({ action, ...values }) => {
          if (action === 'authorization') {
            authorize({ ...values });
          } else {
            authenticate({ ...values, sms: true });
          }
        }}
        render={(formProps) => (
          <Form>
            <Field
              type="phone"
              autocomplete="none"
              name="phone"
              placeholder="Номер телефона"
              component={Input}
            />
            <Field
              autocomplete="none"
              name="code"
              placeholder="Код"
              component={Input}
              onWheel={(e) => e.target.blur()}
            />
            <div>
              <Button
                type="button"
                loading={isAuthentication}
                onClick={async () => {
                  await formProps.setFieldValue('action', 'authentication');
                  formProps.submitForm();
                }}
              >
                Получить код
              </Button>
              <Button
                type="submit"
                loading={isAuthorization}
                onClick={() => {
                  formProps.setFieldValue('action', 'authorization');
                }}
              >
                Войти
              </Button>
            </div>
          </Form>
        )}
      />
      Первый раз в системе?
      <Link to="/registration">Зарегистрируйтесь</Link>
    </FormContainer>
    <Modal
      opened={!!message.text}
      header={message.header}
      onClose={() => setMessage({})}
    >
      {message.text}
    </Modal>
  </>
);

Login.propTypes = {
  message: PropTypes.object.isRequired,
  isAuthentication: PropTypes.bool.isRequired,
  isAuthorization: PropTypes.bool.isRequired,
  authenticate: PropTypes.func.isRequired,
  authorize: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default Login;
