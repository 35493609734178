import React from 'react';
import PropTypes from 'prop-types';

import { Container, BaseInput, Error } from './styles';

const Input = ({
  field,
  form,
  inputRef,
  className,
  ...props
}) => (
  <Container className={className}>
    <BaseInput ref={inputRef} {...field} {...form} {...props} autoComplete="off" />
    {form.touched[field.name] && <Error>{form.errors[field.name]}</Error>}
  </Container>
);

Input.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  inputRef: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
};

Input.defaultProps = {
  field: {
    name: '',
  },
  form: {
    errors: {},
    touched: {},
  },
  inputRef: null,
  className: '',
  size: 'medium',
};

export default Input;
