import React from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import api from 'utils/api';
import { useStore } from 'utils/store';
import { FindBusForm } from 'components';

const loadCities = () => api.get('cities/forBooking');
const saveSearch = ([body]) => api.post(`clients/updateSearches/${body.phone}`, body);

const FindBusFormContainer = ({ history: { push }, location: { search } }) => {
  const [{ user }, setStore] = useStore();

  const { isLoading, data } = useAsync({
    promiseFn: loadCities,
    onResolve: () => window.dispatchEvent(new Event('resize')),
  });
  const { isLoading: isSaving, run } = useAsync({
    deferFn: saveSearch,
    onResolve: (updatedUser) => setStore({ user: updatedUser }),
  });

  const runSearch = (body) => {
    // eslint-disable-next-line
    if (
      user
      && user.savedSearches
      && user.savedSearches[0]
      && (user.savedSearches[0].from !== body.from
        || user.savedSearches[0].to !== body.to)
    ) {
      run({ ...body, phone: user.phone });
    }
  };

  const { from, to, date } = queryString.parse(search);

  const FindBusFormProps = {
    isLoading: isLoading || isSaving,
    cities: data,
    from:
      from
      || (user
        && user.savedSearches
        && user.savedSearches[0]
        && user.savedSearches[0].from),
    to:
      to
      || (user
        && user.savedSearches
        && user.savedSearches[0]
        && user.savedSearches[0].to),
    date,
    search: runSearch,
    push,
  };

  return <FindBusForm {...FindBusFormProps} />;
};

FindBusFormContainer.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default React.memo(withRouter(FindBusFormContainer));
