import styled from 'styled-components';

const Container = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 20px 30px;

  text-align: justify;
  line-height: 1.5;

  h1,h2,h3 {
    word-wrap: break-word;
  }

  h1 {
    font-weight: 700px;
    font-size: 28px;
    margin: 20px 0px;
  }

  h2 {
    font-weight: 500px;
    font-size: 24px;
    margin: 15px 0px;
  }

  h3 {
    font-size: 20px;
    margin: 15px 0px;
  }

  p {
    text-indent: 25px;
    margin: 10px 0px;
  }

  span {
    font-size: 12px;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  ul {
    margin: 10px 0px;
    padding-inline-start: 20px;
    li {
        margin: 7px 0px;
        list-style: initial;
        margin-left: 40px;
    }
  }

  @media (max-width: 768px) {
    padding: 10px 15px;
    font-size: 16px;
    ul {
      padding-inline-start: 10px;
      li {
        margin-left: 20px;
      }
    }
  }
`;

export { Container };
