import styled from 'styled-components';

const Container = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 20px 30px;
  > div {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0px;
    }
    > h3 {
      text-align: center;
      margin-bottom: 15px;
    }
    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        text-align: center;
        margin-bottom: 15px;
        flex: 1;
        > span {
          display: block;
          font-size: 18px;
          margin-bottom: 10px;
        }
        > a {
          display: block;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;

export { Container };
