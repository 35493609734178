import styled from 'styled-components';

const Container = styled.div`
  padding: 65px 20px 75px 20px;
  background: linear-gradient(90deg, #6C00D8 0%, #5AD3FB 100%);
  h2 {
    text-align: center;
    color: #ffffff;
    margin-bottom: 75px;
  }
  > div {
    max-width: 1000px;
    margin: 0 auto;
  }
`;

export {
  Container,
};
