import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Header, Ticket } from 'components';
import { Button, Modal } from 'components/shared';
import { parseDate, formatDate } from 'components/shared/Datepicker/helpers';
import { Footer } from 'layouts/Main/Footer';

import avatar from 'images/avatar.svg';

import { ReservationInfo } from './ReservationInfo';
import {
  Container,
  Content,
  ProfileCard,
  Left,
  UserInfo,
  Tabs,
  Tab,
  Right,
  TicketWrapper,
  TicketInfo,
  ModalFooter,
  Card,
} from './styles';

const TABS = [
  { id: 1, label: 'Брони', value: 'reservations' },
  { id: 2, label: 'Неявки', value: 'absences' },
  { id: 3, label: 'Блокировки', value: 'blockings' },
  { id: 4, label: 'Скидки', value: 'discounts' },
  { id: 5, label: 'Контроль', value: 'supervisions' },
];

const Profile = ({
  user,
  message,
  setMessage,
  declineReservation,
}) => {
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const {
    name,
    surname,
    phone,
    reservations,
    absences,
    blockings,
    discounts,
    supervisions,
  } = user;
  return (
    <Container>
      <Helmet>
        <title>Профиль</title>
      </Helmet>
      <Header />
      <Content>
        <ProfileCard>
          <Left>
            <UserInfo>
              <img src={avatar} alt="avatar" />
              <h3>{`${name} ${surname}`}</h3>
              <span>{phone}</span>
            </UserInfo>
            <Tabs
              first={activeTab.value === TABS[0].value}
              last={activeTab.value === TABS[4].value}
            >
              <span />
              {TABS.map((tab) => (
                <Tab
                  key={tab.id}
                  prev={tab.id === activeTab.id - 1}
                  active={tab.value === activeTab.value}
                  next={tab.id === activeTab.id + 1}
                  onClick={() => setActiveTab(tab)}
                >
                  <span />
                  {tab.label}
                  <span />
                </Tab>
              ))}
              <span />
            </Tabs>
          </Left>
          <Right>
            <h2>{activeTab.label}</h2>
            {(() => {
              switch (activeTab.value) {
                case 'reservations':
                  return reservations.length > 0
                    ? reservations.map((reservation, index) => (
                      <TicketWrapper key={reservation.id}>
                        <Ticket
                          header={formatDate(parseDate(reservation.date, 'yyyy-MM-dd'), 'dd.MM.yyyy')}
                          from={{
                            city: reservation.from.city,
                            time: reservation.from.time,
                            stop: reservation.from.name,
                          }}
                          to={{
                            city: reservation.to.city,
                            time: reservation.to.time,
                            stop: reservation.to.name,
                          }}
                          price={`${reservation.price}р.`}
                          color={index % 2 === 0 ? 'blue' : 'purple'}
                        />
                        <TicketInfo>
                          <Button onClick={() => setMessage({
                            header: 'Информация о брони',
                            reservation,
                          })}
                          >
                            Подробнее
                          </Button>
                          <Button
                            color="red"
                            onClick={() => setMessage({
                              header: 'Отмена брони',
                              text: 'Вы действительно хотите отменить бронь?',
                              footer: true,
                              reservationId: reservation.id,
                            })}
                          >
                            Отменить
                          </Button>
                        </TicketInfo>
                      </TicketWrapper>
                    ))
                    : 'У вас нет активных броней';
                case 'absences':
                  return absences.length > 0
                    ? absences.map((absence) => (
                      <TicketWrapper key={absence.id}>
                        <Ticket
                          header={formatDate(parseDate(absence.date, 'yyyy-MM-dd'), 'dd.MM.yyyy')}
                          from={{
                            city: absence.from.city,
                            time: absence.from.time,
                            stop: absence.from.name,
                          }}
                          to={{
                            city: absence.to.city,
                            time: absence.to.time,
                            stop: absence.to.name,
                          }}
                          color="gray"
                        />
                        <TicketInfo>
                          <Button>
                            Подробнее
                          </Button>
                        </TicketInfo>
                      </TicketWrapper>
                    ))
                    : 'У вас нет неявок';
                case 'blockings':
                  return blockings.length > 0
                    ? blockings.map((blocking) => (
                      <Card key={blocking.id}>
                        <div>
                          <span>Маршрут</span>
                          <span>{`${blocking.route.number} ${blocking.route.name}`}</span>
                        </div>
                        <div>
                          <span>Перевозчик</span>
                          <Link to="/carriers">{blocking.carrier.name}</Link>
                        </div>
                      </Card>
                    ))
                    : 'У вас нет блокировок';
                case 'discounts':
                  return discounts.length > 0
                    ? discounts.map((discount) => (
                      <Card key={discount.id}>
                        <div>
                          <span>Маршрут</span>
                          <span>{`${discount.route.number} ${discount.route.name}`}</span>
                        </div>
                        <div>
                          <span>Перевозчик</span>
                          <Link to="/carriers">{discount.carrier.name}</Link>
                        </div>
                        {discount.permanentLevel > 0 && (
                          <div>
                            <span>Постоянная</span>
                            <span>{`${discount.permanentLevel} р.`}</span>
                          </div>
                        )}
                        {discount.byCountLevel > 0 && (
                          <div>
                            <span>Маршрут</span>
                            <span>{`${discount.byCountLevel} р.`}</span>
                          </div>
                        )}
                        {discount.byDateLevel > 0 && (
                          <div>
                            <span>Маршрут</span>
                            <span>{`${discount.byDateLevel} р.`}</span>
                          </div>
                        )}
                      </Card>
                    ))
                    : 'У вас нет скидок';
                case 'supervisions':
                  return supervisions.length > 0
                    ? supervisions.map((supervision) => (
                      <Card key={supervision.id}>
                        <div>
                          <span>Маршрут</span>
                          <span>{`${supervision.route.number} ${supervision.route.name}`}</span>
                        </div>
                        <div>
                          <span>Перевозчик</span>
                          <Link to="/carriers">{supervision.carrier.name}</Link>
                        </div>
                      </Card>
                    ))
                    : 'У вас нет отметок о контроле';
                default:
                  return null;
              }
            })()}
          </Right>
        </ProfileCard>
      </Content>
      <Footer />
      <Modal
        opened={Object.keys(message).length > 0}
        header={message.header}
        onClose={() => setMessage({})}
        footer={message.footer && (
          <ModalFooter>
            <Button onClick={() => setMessage({})}>Нет</Button>
            <Button
              onClick={() => declineReservation({ reservation: message.reservationId })}
            >
              Да
            </Button>
          </ModalFooter>
        )}
      >
        {message.text}
        {message.reservation && <ReservationInfo reservation={message.reservation} />}
      </Modal>
    </Container>
  );
};

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  setMessage: PropTypes.func.isRequired,
  declineReservation: PropTypes.func.isRequired,
};

export default Profile;
