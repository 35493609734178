import styled, { css } from 'styled-components';

const Container = styled.div`
  max-width: 1040px;
  margin: 0 auto;
  form {
    display: flex;
    flex-direction: column;
  }
  button {
    align-self: flex-end;
  }
`;

const Info = styled.div`
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 75px;
  h3 {
    margin-bottom: 20px;
  }
  div {
    :not(:last-child) {
      margin-bottom: 8px;
    }
    span {
      font-size: 21px;
    }
    span:first-child {
      margin-right: 10px;
    }
    span:last-child {
      color: #606060;
    }
  }

  @media (max-width: 768px) {
    div {
      span {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 575px) {
    margin-bottom: 35px;
    padding: 15px;
    div {
      span {
        font-size: 16px;
      }
    }
    a {
      font-size: 16px;
    }
  }
`;

const Fields = styled.div`
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-bottom: 40px;
  padding: 10px 0px;
  > div > input {
    border-bottom: none;
    margin-bottom: 0px;
    padding: 20px 40px;
    border-radius: 20px;
  }

  @media (max-width: 575px) {
    > div > input {
      padding: 10px 20px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: #E5E5E5;
`;

const Amount = styled.div`
  display: flex;
  padding: 20px 40px;
  font-size: 24px;
  justify-content: space-between;
  align-items: center;
  div:first-child {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 20px;
  }

  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

const Label = styled.span`
  display: flex;
  align-items: center;
`;

const Control = styled.span`
  :not(:last-child) {
    margin-right: 20px;
  }
  ${(props) => props.active && css`
    color: #6C00D8;
    font-weight: 600;
  `}
  &:hover {
    cursor: pointer;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export {
  Container,
  Info,
  Fields,
  Divider,
  Amount,
  Label,
  Control,
  ModalFooter,
};
