import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import LogRocket from 'logrocket';

import { StoreProvider } from 'utils/store';
import theme, { GlobalStyle, ThemeLogger } from 'theme';
import 'react-day-picker/lib/style.css';
import 'fonts.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

const MOUNT_NODE = document.getElementById('root');

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('fypotu/obs-web');
}

ReactDOM.render(
  <BrowserRouter>
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <>
          <App />
          <GlobalStyle />
          <ThemeLogger />
        </>
      </ThemeProvider>
    </StoreProvider>
  </BrowserRouter>,
  MOUNT_NODE,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
