import React from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import { formatDate, parseDate } from 'components/shared/Datepicker/helpers';
import ym from 'react-yandex-metrika';

import {
  Button, Datepicker, Icon, LoadingIndicator, Select,
} from 'components/shared';
import { Helmet } from 'react-helmet/es/Helmet';
import {
  Container, Divider, Fields, SelectsContainer, Swap,
} from './styles';

const validationSchema = Yup.object().shape({
  from: Yup.object().required(),
  to: Yup.object().required(),
  date: Yup.date().required(),
});

const getInitialValues = (cities, from, to, date) => {
  const cityObject = cities.find((city) => city.from.id === from) || cities[0];
  const dateObject = parseDate(date, 'yyyy-MM-dd') || new Date();
  const fromObject = cityObject.from;
  const toObject = cityObject.to.find((city) => city.id === to) || cityObject.to[0];
  return ({
    from: fromObject,
    to: toObject,
    date: dateObject,
    direction: 'direct',
  });
};

const FindBusForm = ({
  isLoading,
  cities,
  from,
  to,
  date,
  search,
  push,
}) => {
  const preparedCity = !isLoading && getInitialValues(cities, from, to, date);
  const m = new Set();
  cities.forEach((c) => {
    m.add(c.from.name);
    c.to.forEach((tc) => m.add(tc.name));
  });

  return (
    <Container>
      <Helmet
        meta={
          [{
            name: 'keywords',
            content: Array.from(m).join(', '),
          }]
        }
      />
      <Formik
        // FIXME: refactor form
        key={`FindBusForm-${isLoading}`}
        validationSchema={validationSchema}
        initialValues={!isLoading && preparedCity}
        onSubmit={(values) => {
          ym('reachGoal', 'search');
          search({ from: values.from.id, to: values.to.id });
          push(`/search?from=${values.from.id}&to=${values.to.id}&date=${formatDate(values.date, 'yyyy-MM-dd')}`);
        }}
        render={(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <Fields>
              {isLoading && <LoadingIndicator overlay />}
              <SelectsContainer>
                <div>
                  <Field
                    name="from"
                    component={({ field: { value, name, onChange } }) => (
                      <Select
                        placeholder="Отправление"
                        items={cities.map((city) => city.from)}
                        value={value}
                        onSelect={(newValue) => {
                          onChange({ target: { name, value: newValue } });
                          onChange({ target: { name: 'to', value: null } });
                        }}
                        labelKey={(item) => `${item.name} (${item.area} р-н., ${item.region} обл.)`}
                        valueKey="id"
                        searchable
                        searchKey="name"
                        searchPlaceholder="Поиск"
                        noResultsPlaceholder="Нет результатов"
                        icon="point"
                      />
                    )}
                  />
                  <Divider />
                  <Field
                    name="to"
                    component={({ field: { value, name, onChange } }) => (
                      <Select
                        placeholder="Прибытие"
                        items={
                          formProps.values.from
                            ? cities.find((city) => city.from.id === formProps.values.from.id).to
                            : []
                        }
                        value={value}
                        onSelect={(newValue) => onChange({ target: { name, value: newValue } })}
                        labelKey={(item) => `${item.name} (${item.area} р-н., ${item.region} обл.)`}
                        valueKey="id"
                        searchable
                        searchPlaceholder="Поиск"
                        searchKey="name"
                        noResultsPlaceholder="Нет результатов"
                        icon="point"
                        disabled={!formProps.values.from}
                      />
                    )}
                  />
                </div>
                <Swap
                  direction={formProps.values.direction}
                  onClick={() => {
                    formProps.setFieldValue('direction', formProps.values.direction === 'direct' ? 'reverse' : 'direct');
                    const oldValues = { ...formProps.values };
                    formProps.setFieldValue('from', oldValues.to);
                    formProps.setFieldValue('to', oldValues.from);
                  }}
                >
                  <Icon
                    type="swap"
                    size={30}
                  />
                </Swap>
              </SelectsContainer>
              <Divider />
              <Field
                name="date"
                component={({ field: { value, name, onChange } }) => (
                  <Datepicker
                    value={value}
                    onChange={(newValue) => onChange({ target: { name, value: newValue } })}
                  />
                )}
              />
            </Fields>
            <Button
              primary
              disabled={
                !formProps.values.from
                || !formProps.values.to
                || !formProps.values.date
              }
            >
              Поиск
            </Button>
          </form>
        )}
      />
    </Container>
  );
};

FindBusForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  cities: PropTypes.array,
  from: PropTypes.string,
  to: PropTypes.string,
  date: PropTypes.string,
  push: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
};

FindBusForm.defaultProps = {
  cities: [],
  from: null,
  to: null,
  date: null,
};

export default FindBusForm;
