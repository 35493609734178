import styled from 'styled-components';

const Container = styled.div`
  div {
    :not(:last-child) {
      margin-bottom: 15px;
    }
    span {
      font-size: 21px;
    }
    span:first-child {
      margin-right: 10px;
    }
    span:last-child {
      color: #606060;
    }
  }

  @media (max-width: 768px) {
    div {
      :not(:last-child) {
        margin-bottom: 10px;
      }
      span {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 575px) {
    div {
      :not(:last-child) {
        margin-bottom: 5px;
      }
      span {
        font-size: 16px;
      }
    }
    a {
      font-size: 16px;
    }
  }
`;

export { Container };
