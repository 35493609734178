import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Carriers = ({ carriers }) => (
  <Container>
    {carriers.map((carrier) => (
      <div key={carrier.id}>
        <h3>{carrier.name}</h3>
        <div>
          <div>
            <span>УНП</span>
            <span>{carrier.unp}</span>
          </div>
          <div>
            <span>Адрес юридический</span>
            <span>{carrier.adressLegal}</span>
          </div>
          <div>
            <span>Адрес местонахождения книги жалоб и предложений</span>
            <span>{carrier.adressBook}</span>
          </div>
          <div>
            <span>Номер лицензии</span>
            <span>{carrier.licenseNumber}</span>
          </div>
          {carrier.licenseIssueDate && (
          <div>
            <span>Дата выдачи лицензии</span>
            <span>{carrier.licenseIssueDate}</span>
          </div>
          )}
          {carrier.legalPhone && (
          <div>
            <span>Телефоны для связи</span>
            <span>{carrier.legalPhone}</span>
          </div>
          )}
        </div>
      </div>
    ))}
  </Container>
);

Carriers.propTypes = {
  carriers: PropTypes.array.isRequired,
};

export default Carriers;
