import styled from 'styled-components';

const Container = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 20px 30px;
  display: flex;
  flex-wrap: wrap;
  > div {
    flex: 1;
    min-width: 420px;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0px;
    }
    > h3 {
      text-align: center;
      margin-bottom: 15px;
    }
    > div {
      > div {
        margin-bottom: 10px;
        text-align: center;
        > span {
          display: block;
        }
        > span:first-child {
          font-size: 14px;
          color: #606060;
        }
        > span:last-child {
          font-size: 18px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 10px 15px;
  }

  @media (max-width: 575px) {
    > div {
      min-width: 255px;
    }
  }
`;

export { Container };
