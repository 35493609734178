import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const RouteWithLayout = ({
  path,
  title,
  layout: Layout,
  component: Component,
  ...otherProps
}) => (
  <Route
    path={path}
    {...otherProps}
    render={(matchProps) => (
      <Layout title={title}>
        <Component {...matchProps} />
      </Layout>
    )}
  />
);

RouteWithLayout.propTypes = {
  layout: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  component: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};

export default RouteWithLayout;
