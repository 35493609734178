import React from 'react';
import PropTypes from 'prop-types';

import { icons } from './iconPaths';
import { IconSvg, IconWrapper } from './styles';

const Icon = ({
  type,
  className,
  size,
  color,
  hoverColor,
}) => (
  <IconWrapper className={className} size={size}>
    <IconSvg
      color={color}
      hoverColor={hoverColor}
      size={size}
    >
      <path d={icons[type]} />
    </IconSvg>
  </IconWrapper>
);

Icon.propTypes = {
  type: PropTypes.oneOf(Object.keys(icons)).isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  hoverColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Icon.defaultProps = {
  className: '',
  size: 22,
  color: '#606060',
  hoverColor: '',
};

export default Icon;
