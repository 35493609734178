import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Contacts = ({ routes }) => (
  <Container>
    {routes.map((route) => (
      <div key={route.id}>
        <h3>{`${route.number} ${route.name}`}</h3>
        <div>
          {route.carriers.map((carrier) => (
            <div key={carrier.id}>
              <span>{carrier.name}</span>
              {carrier.phone.map((phone) => (
                <a key={phone} href={`tel:${phone}`}>{phone}</a>
              ))}
            </div>
          ))}
        </div>
      </div>
    ))}
  </Container>
);

Contacts.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Contacts;
