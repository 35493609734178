import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #ffffff;
  padding: 20px;
  :hover {
    cursor: pointer;
  }
  > div:last-child {
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 20px;
  }
  
  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

const Menu = styled.div`
  position: absolute;
  min-width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0,0,0,.15);
  border-radius: 5px;
  z-index: 100;
  padding: 5px 0px;
`;

const MenuItem = styled.div`
  padding: 20px 40px;
  font-size: 24px;
  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 20px;
  }
  
  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

export {
  Container,
  Label,
  Menu,
  MenuItem,
};
