import React from 'react';
import { Link } from 'react-router-dom';

import {
  Container, Content, Links, Info, Copyright,
} from './styles';

const Footer = () => (
  <Container>
    <Content>
      <Links>
        <Link to="/contacts">Диспетчерские службы</Link>
        <Link to="/carriers">Перевозчики</Link>
        <Link to="/legal/terms">Условия и положения</Link>
        <Link to="/legal/privacy">Политика конфиденциальности</Link>
        <Link to="/legal/rules">Права и обязанности пассажиров</Link>
        <Link to="/legal/payment-rules">Правила оплаты</Link>
      </Links>
      <Copyright>© 2020 Online Bus Station</Copyright>
      <Info>
        ООО «Центр цифровых транспортных технологий» (Общество с ограниченной
        ответственностью «Центр цифровых транспортных технологий»)
        <br />
        Свидетельство о государственной регистрации №692178478 от 23.02.2021
        выдано Минским райисполкомом
        <br />
        223053, Минская область, Минский район, Боровлянский сельсовет, д.
        Боровая, тер. ТВЦ «Аквабел», д. 1, оф. 419, тел: 80173647812, email:
        dttcby@gmail.com
      </Info>
    </Content>
  </Container>
);

export default Footer;
