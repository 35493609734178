import React from 'react';
import PropTypes from 'prop-types';

import { Container, Point, Overlay } from './styles';

const Spinner = ({ scale, color }) => (
  <Container scale={scale} color={color}>
    <Point />
    <Point />
    <Point />
    <Point />
    <Point />
    <Point />
    <Point />
    <Point />
    <Point />
    <Point />
    <Point />
    <Point />
  </Container>
);

Spinner.propTypes = {
  scale: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

const LoadingIndicator = ({ overlay, scale, color }) => {
  if (overlay) {
    return (
      <Overlay>
        <Spinner scale={scale} color={color} />
      </Overlay>
    );
  }
  return <Spinner scale={scale} color={color} />;
};

LoadingIndicator.propTypes = {
  overlay: PropTypes.bool,
  scale: PropTypes.number,
  color: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  overlay: false,
  scale: 1,
  color: '#FB6D2E',
};

export default LoadingIndicator;
