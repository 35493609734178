import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'components/shared';

import {
  Container,
  Label,
  Menu,
  MenuItem,
} from './styles';

const renderLabel = (item, labelKey) => {
  if (labelKey) {
    if (typeof labelKey === 'string') {
      return item[labelKey];
    }
    return labelKey(item);
  }
  return item;
};

const Dropdown = ({
  children,
  items,
  labelKey,
  onSelect,
}) => {
  const menuRef = useRef(null);
  const [opened, setOpened] = useState(false);
  useEffect(() => {
    const onDocumentClick = (e) => {
      if (opened && ![menuRef.current].includes(e.target)) {
        setOpened(false);
      }
    };
    document.addEventListener('click', onDocumentClick);
    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [opened]);

  return (
    <Container>
      <Label onClick={() => setOpened(true)}>
        {children}
        <Icon type="arrowDropdown" color="#ffffff" />
      </Label>
      {opened
        && (
          <Menu ref={menuRef}>
            {items.map((item) => (
              <MenuItem
                onClick={() => onSelect(item)}
                key={labelKey ? item[labelKey] : item}
              >
                {renderLabel(item, labelKey)}
              </MenuItem>
            ))}
          </Menu>
        )}
    </Container>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node,
  items: PropTypes.array,
  labelKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onSelect: PropTypes.func,
};

Dropdown.defaultProps = {
  children: null,
  items: [],
  labelKey: null,
  onSelect: () => { },
};

export default Dropdown;
