import styled from 'styled-components';

const Container = styled.div`
  max-width: 1240px;
  padding: 0px 20px;
  margin: 0 auto;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    text-decoration: none;
    :first-child {
      margin-right: 20px;
    }
  }
  
  @media (max-width: 768px) {
    height: 100px;
    img {
      width: 130px;
    }
    a {
      font-size: 22px;
    }
  }

  @media (max-width: 575px) {
    padding: 0px 10px;
    height: 80px;
    img {
      width: 100px;
    }
    a {
      font-size: 18px;
    }
  }
`;

export { Container };
