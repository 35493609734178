import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    const MOUNT_NODE = document.getElementById('root');
    if (MOUNT_NODE.scrollTo) {
      MOUNT_NODE.scrollTo(0, 0);
    } else {
      MOUNT_NODE.scrollTop = 0;
    }
  }, [pathname]);

  return children;
};

export default withRouter(ScrollToTop);
