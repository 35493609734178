import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  h3 {
    max-width: calc(100% - 220px);
    z-index: 1;
  }
  > span {
    position: absolute;
    top: 50px;
    right: 115px;
    color: #ffffff;
    font-size: 36px;
    font-weight: 500;
    width: 150px;
    text-align: center;
    z-index: 1;
  }
  > img {
    position: absolute;
    top: 0px;
    right: 50px;
    :last-child {
      top: 20px;
      right: 20px;
    }
  }

  @media (max-width: 1024px) {
    h3 {
      max-width: calc(100% - 200px);
    }
    > span {
      top: 40px;
      right: 95px;
      font-size: 32px;
    }
    > img {
      width: 225px;
      :last-child {
        width: auto;
      }
    }
  }

  @media (max-width: 575px) {
    padding: 15px;
    h3 {
      font-size: 18px;
      max-width: calc(100% - 160px);
    }
    > span {
      top: 20px;
      right: 20px;
      font-size: 28px;
    }
    > img {
      width: 150px;
      right: 20px;
      :last-child {
        display: none;
      }
    }
  }

  @media (max-width: 420px) {
    h3 {
      font-size: 18px;
      max-width: calc(100% - 140px);
    }
    > span {
      right: 5px;
      font-size: 24px;
    }
    > img {
      width: 135px;
      right: 10px;
      :last-child {
        display: none;
      }
    }
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  padding: 20px 0px 0px 60px;
  img {
   position: absolute;
   top: 30px;
   left: 10px;
  }
  div {
    span {
      display: block;
      font-size: 21px;
    }
    span:last-child {
      color: #606060;
      margin-bottom: 15px;
    }
  }

  @media (max-width: 575px){
    img {
      top: 20px;
    }
    div {
      span {
        font-size: 16px;
      }
    }
  }
`;

export {
  Container,
  Content,
};
