import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const Value = styled.div`
  padding: 20px 40px;
  font-size: 24px;
  display: flex;
  align-items: center;
  div {
    margin-right: 20px;
  }
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 20px;
  }

  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

const Label = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Menu = styled.div`
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0,0,0,.15);
  border-radius: 5px;
  z-index: 100;
  input { 
    padding: 20px 40px;
  }

  @media (max-width: 768px) {
    input {
      padding: 10px 20px;
    }
  }
`;

const MenuItems = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const MenuItem = styled.div`
  padding: 20px 40px;
  font-size: 24px;
  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 20px;
  }
  
  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

const NoResults = styled.div`
  padding: 20px 40px;
  font-size: 24px;
`;

export {
  Container,
  Value,
  Label,
  Menu,
  MenuItems,
  MenuItem,
  NoResults,
};
