import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input, Icon } from 'components/shared';

import {
  Container,
  Value,
  Label,
  Menu,
  MenuItem,
  MenuItems,
  NoResults,
} from './styles';

const renderLabel = (item, labelKey) => {
  if (labelKey) {
    if (typeof labelKey === 'string') {
      return item[labelKey];
    }
    return labelKey(item);
  }
  return item;
};

const Select = ({
  items,
  value,
  valueKey,
  labelKey,
  onSelect,
  placeholder,
  searchable,
  searchKey,
  searchPlaceholder,
  noResultsPlaceholder,
  icon,
  disabled,
  className,
}) => {
  const valueRef = useRef(null);
  const menuRef = useRef(null);
  const inputRef = useRef(null);
  const [opened, setOpened] = useState(false);
  const [search, setSearch] = useState('');
  useEffect(() => {
    const onDocumentClick = (e) => {
      if (opened && ![valueRef.current, menuRef.current, inputRef.current].includes(e.target)) {
        setOpened(false);
        if (searchable) {
          setSearch('');
        }
      }
    };
    document.addEventListener('click', onDocumentClick);
    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [opened, searchable]);

  const filteredItems = items
    .filter((item) => (searchKey ? item[searchKey] : renderLabel(item, labelKey)).toLowerCase()
      .includes(search.toLowerCase()));

  return (
    <Container className={className}>
      <Value
        ref={valueRef}
        onClick={() => {
          if (!opened && !disabled) {
            setOpened(true);
            if (searchable) {
              setTimeout(() => inputRef.current.focus(), 0);
            }
          }
        }}
      >
        {icon && <Icon type={icon} />}
        {value && <Label>{renderLabel(value, labelKey)}</Label>}
        {!value && placeholder}
      </Value>
      {opened
        && (
          <Menu ref={menuRef}>
            {searchable && (
              <Input
                inputRef={inputRef}
                placeholder={searchPlaceholder}
                value={search}
                onChange={({ target: { value: newSearch } }) => setSearch(newSearch)}
              />
            )}
            <MenuItems>
              {filteredItems.length > 0
                ? filteredItems.map((item) => (
                  <MenuItem
                    onClick={() => {
                      if (value) {
                        if (valueKey
                          ? item[valueKey] !== value[valueKey]
                          : item !== value
                        ) {
                          onSelect(item);
                        }
                      } else {
                        onSelect(item);
                      }
                    }}
                    key={valueKey ? item[valueKey] : item}
                  >
                    {renderLabel(item, labelKey)}
                  </MenuItem>
                ))
                : <NoResults>{noResultsPlaceholder}</NoResults>}
            </MenuItems>
          </Menu>
        )}
    </Container>
  );
};

Select.propTypes = {
  items: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  valueKey: PropTypes.string,
  labelKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  searchKey: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  noResultsPlaceholder: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Select.defaultProps = {
  items: [],
  value: null,
  valueKey: null,
  labelKey: null,
  onSelect: () => { },
  placeholder: 'Select',
  searchable: false,
  searchKey: null,
  searchPlaceholder: 'Search',
  noResultsPlaceholder: 'No results',
  icon: null,
  disabled: false,
  className: '',
};

export default Select;
