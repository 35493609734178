import lazy from 'utils/lazy';

const BookingPage = lazy(() => import('./booking'));
const CarriersPage = lazy(() => import('./carriers'));
const ContactsPage = lazy(() => import('./contacts'));
const LegalPage = lazy(() => import('./legal'));
const LoginPage = lazy(() => import('./login'));
const ProfilePage = lazy(() => import('./profile'));
const RegistrationPage = lazy(() => import('./registration'));
const MainPage = lazy(() => import('./main'));
const SearchPage = lazy(() => import('./search'));
const SettingsPage = lazy(() => import('./settings'));

export {
  BookingPage,
  CarriersPage,
  ContactsPage,
  LegalPage,
  LoginPage,
  ProfilePage,
  RegistrationPage,
  MainPage,
  SearchPage,
  SettingsPage,
};

export { default as NotFoundPage } from './not-found';
