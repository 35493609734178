import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { Header } from './Header';
import { Footer } from './Footer';
import { Container, Content } from './styles';

const Main = ({ children, title }) => (
  <Container>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Header />
    <Content>
      {children}
    </Content>
    <Footer />
  </Container>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Main;
