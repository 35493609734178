import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { parseDate, formatDate } from 'components/shared/Datepicker/helpers';

import { Container } from './styles';

const ReservationInfo = ({ reservation }) => (
  <Container>
    <div>
      <span>Когда</span>
      <span>{formatDate(parseDate(reservation.date, 'yyyy-MM-dd'), 'dd.MM.yyyy')}</span>
    </div>
    <div>
      <span>Отправление</span>
      <span>{`${reservation.from.time} ${reservation.from.name} (${reservation.from.city})`}</span>
    </div>
    <div>
      <span>Прибытие</span>
      <span>{`${reservation.to.time} ${reservation.to.name} (${reservation.to.city})`}</span>
    </div>
    <div>
      <span>Маршрут</span>
      <span>{`${reservation.route.number} ${reservation.route.name}`}</span>
    </div>
    <div>
      <span>Перевозчик</span>
      <Link to="/carriers">{reservation.carrier.name}</Link>
    </div>
    {reservation.limit.showPlaces && (
      <div>
        <span>Номера мест</span>
        <span>{reservation.places.join(', ')}</span>
      </div>
    )}
    <div>
      <span>Стоимость</span>
      <span>{`${reservation.price}р.`}</span>
    </div>
    {(reservation.limit.showCar || reservation.limit.showCarDescription) && (
      <div>
        <span>Машина</span>
        <span>{`${reservation.limit.showCarDescription ? `${reservation.car.description} ` : ''}${reservation.limit.showCar ? reservation.car.number : ''}`}</span>
      </div>
    )}
    {(reservation.limit.showDriverPhone
      || reservation.limit.showDriverName
      || reservation.limit.showDriverSurname
    ) && (
    <div>
      <span>Водитель</span>
      <span>
        {`${reservation.limit.showDriverPhone ? `${reservation.driver.phone} ` : ''}
          ${reservation.limit.showDriverName ? reservation.driver.name : ''}
          ${reservation.limit.showDriverSurname ? reservation.driver.surname : ''}`}
      </span>
    </div>
    )}
  </Container>
);

ReservationInfo.propTypes = {
  reservation: PropTypes.object.isRequired,
};

export default ReservationInfo;
